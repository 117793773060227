import { QueryResult, useMutation, useQuery } from "@apollo/client";
import React, { createContext, useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import {
  GetLinkedInPostsByMarketigUnitDocument,
  GetLinkedInPostsByMarketigUnitQuery,
  GetMarketingUnitDocument,
  GetMarketingUnitQuery,
  InsertInterviewDocument,
  UpdateInterviewDocument,
  UpdateMarketingUnitDocument,
} from "../graphql/__generated__/graphql";
import { Loading } from "./Loading";
import { DisplayError } from "./DisplayError";
import Sidebar from "./Sidebar";
import { UserInReactContext } from "./InitialiseApp";
import { InterviewResponses } from "../types";
import { toObjectWithArraysAsLiterals } from "../utils";
import { useNotificationWithMutation } from "../hooks/useNotificationWithMutation";
import { Container, Content } from "rsuite";

export type MarketingUnitByPk = NonNullable<
  GetMarketingUnitQuery["marketing_unit_by_pk"]
>;

interface MarketingUnitProviderContext {
  marketingUnit: NonNullable<MarketingUnitByPk>;
  onInterviewSubmit(responses: InterviewResponses): void;
  postsQuery: QueryResult<
    GetLinkedInPostsByMarketigUnitQuery,
    {
      id: number;
    }
  >;
  marketingUnitQuery: QueryResult<GetMarketingUnitQuery, { id: number }>;
}

export const MarketingUnitContext = createContext<
  MarketingUnitProviderContext | undefined
>(undefined);

export const useMarketingUnit = (): MarketingUnitProviderContext => {
  const context = useContext(MarketingUnitContext);
  if (!context) {
    throw new Error(
      "useMarketingUnit must be used within a MarketingUnitProvider"
    );
  }
  return context;
};

export const MarketingUnitProvider: React.FC<{ user: UserInReactContext }> = ({
  user,
}) => {
  const { id } = useParams();
  if (!id || Number.isNaN(id)) {
    return (
      <p>
        Invalid request, missing correct marketing unit id in the path
        parameter.
      </p>
    );
  }
  const postsQuery = useQuery(GetLinkedInPostsByMarketigUnitDocument, {
    variables: { id: Number(id) },
  });
  const [insertInterview] = useNotificationWithMutation(
    useMutation(InsertInterviewDocument),
    {
      loadingMessage: "Saving interview",
      successMessage: "Interview saved",
      errorMessage: "Failed to save interview",
    }
  );
  const [updateInterview] = useNotificationWithMutation(
    useMutation(UpdateInterviewDocument),
    {
      loadingMessage: "Saving interview",
      successMessage: "Interview saved",
      errorMessage: "Failed to save interview",
    }
  );

  const [updateMarketingUnitDocument] = useNotificationWithMutation(
    useMutation(UpdateMarketingUnitDocument),
    {
      loadingMessage: "Saving marketing unit",
      successMessage: "Marketing unit saved",
      errorMessage: "Failed to save marketing unit",
    }
  );

  const marketingUnitQuery = useQuery(GetMarketingUnitDocument, {
    variables: {
      id: Number(id),
    },
    skip: id === undefined,
  });

  const {
    loading,
    error,
    data,
    refetch: refetchMarketingUnit,
  } = marketingUnitQuery;

  if (id == undefined) {
    return (
      <p>Invalid request, missing marketing unit id in the path parameter.</p>
    );
  } else if (loading || !data || !data.marketing_unit_by_pk) {
    return <Loading />;
  }

  if (error) return <DisplayError error={error} />;

  const marketingUnit = data.marketing_unit_by_pk;

  const onInterviewSubmit = async (responses: InterviewResponses) => {
    if (!marketingUnit) return;

    const { interview } = marketingUnit;

    if (!interview) {
      const { data: insertInterviewData } = await insertInterview({
        variables: {
          object: {
            ...toObjectWithArraysAsLiterals(responses),
          },
        },
      });
      if (insertInterviewData && insertInterviewData.insert_interview_one) {
        await updateMarketingUnitDocument({
          variables: {
            id: marketingUnit.id,
            _set: {
              interviewId: insertInterviewData.insert_interview_one.id,
            },
          },
        });
      }
    } else {
      await updateInterview({
        variables: {
          id: interview.id,
          _set: {
            ...toObjectWithArraysAsLiterals(responses),
          },
        },
      });
    }
    await refetchMarketingUnit();
  };

  return (
    <MarketingUnitContext.Provider
      value={{ marketingUnit, onInterviewSubmit, postsQuery, marketingUnitQuery }}
    >
      <Container>
        <Sidebar user={user} />
        <Content className="pl-2">
          <Outlet />
        </Content>
      </Container>
    </MarketingUnitContext.Provider>
  );
};

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export const PropertyDiff:  React.FC<{
    entity: any;
    input: any;
    propName: string;
    propDisplayName: string;
}> = ({ entity, input, propName, propDisplayName }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const prevValue = entity[propName];
  const currentValue = input[propName];

  const isUnsaved = currentValue !== prevValue;

  function handleMouseEnter(e) {
    setShowTooltip(true);
    setTooltipPosition({ x: e.clientX, y: e.clientY });
  }

  function handleMouseLeave() {
    setShowTooltip(false);
  }

  return (
    <div className="mt-1">
      {isUnsaved && (
        <div>
          {propDisplayName} is unsaved{" "}
          <FontAwesomeIcon onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} icon={faQuestionCircle} />
        </div>
      )}
      {showTooltip && (
        <div
          className="tooltip"
          style={{ left: tooltipPosition.x, top: tooltipPosition.y }}
        >
          <div><b>Previous value: </b>{prevValue}</div>
          <div><b>Current value: </b>{currentValue}</div>
        </div>
      )}
    </div>
  );
}

import useAxios from "axios-hooks";
import React, { useState } from "react";
import {
  Affix,
  Button,
  ButtonToolbar,
  Divider,
  Form,
  InputPicker,
  Message,
  Panel,
  Radio,
  RadioGroup,
  Schema,
  Stack,
  useToaster,
} from "rsuite";
import PlaceholderGrid from "rsuite/esm/Placeholder/PlaceholderGrid";
import SelectTile from "../SelectTile";
import SelectTileGroup from "../SelectTileGroup";
import Icon from "@rsuite/icons/lib/Icon";
import { FaSearch, FaUpload } from "react-icons/fa";
import { useLinkedInPost } from "../LinkedInPostContent";

const supportedColors = [
  "any",
  "red",
  "orange",
  "yellow",
  "green",
  "turquoise",
  "blue",
  "violet",
  "pink",
  "brown",
  "black",
  "gray",
  "white",
];

const validationModel = Schema.Model({
  query: Schema.Types.StringType().isRequired("This field is required."),
});

const PexelsAssetPicker: React.FC = () => {
  const { onUploadAssets } = useLinkedInPost();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [
    { data: dataSearchPexels, loading: loadingSearchPexels },
    searchPexels,
  ] = useAxios(
    {
      baseURL: `${process.env.API_URL}/pexels/images/search`,
      method: "get",
    },
    {
      manual: true,
    }
  );
  const toaster = useToaster();
  const [formValue, setFormValue] = useState<Record<string, any>>({
    query: "",
    orientation: "",
    color: "",
  });
  const [selectedImagesIds, setSelectedImagesIds] = useState<string[]>([]);
  const handleUploadPhotos = async () => {
    setLoadingUpload(true);
    try {
      if (!onUploadAssets) return;
      await onUploadAssets(
        dataSearchPexels.photos.filter((p) => selectedImagesIds.includes(p.id)),
        "pexels"
      );
      setSelectedImagesIds([]);
      setLoadingUpload(false);
    } catch (error) {
      setSelectedImagesIds([]);
      setLoadingUpload(false);
    }
  };

  const handleSubmit = async (isValid) => {
    if (!isValid) return;
    setSelectedImagesIds([]);
    if (
      dataSearchPexels &&
      !!dataSearchPexels.photos &&
      dataSearchPexels.photos.length > 0
    ) {
      dataSearchPexels.photos = [];
    }
    const { status, statusText } = await searchPexels({
      params: formValue,
    });
    if (status !== 200) {
      toaster.push(
        <Message closable showIcon type="error">
          {statusText}
        </Message>
      );
    }
  };

  return (
    <Panel>
      <Form
        onSubmit={handleSubmit}
        fluid
        model={validationModel}
        formValue={formValue}
        onChange={setFormValue}
      >
        <Form.Group>
          <Form.ControlLabel>Search query</Form.ControlLabel>
          <Form.Control name="query" value={formValue.query}></Form.Control>
          <Form.HelpText>Search for a photo</Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Orientation</Form.ControlLabel>
          <Form.Control
            accepter={RadioGroup}
            name="orientation"
            inline
            value={formValue.orientation}
          >
            <Radio value="">Any</Radio>
            <Radio value="landscape">Landscape</Radio>
            <Radio value="portrait">Portrait</Radio>
            <Radio value="square">Square</Radio>
          </Form.Control>
          <Form.HelpText>
            Select the orientation of the images you want to search for.
            <br />
            - "Landscape" for horizontal images.
            <br />
            - "Portrait" for vertical images.
            <br />- "Square" for images with equal width and height.
          </Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Color</Form.ControlLabel>
          <Form.Control
            accepter={InputPicker}
            data={supportedColors.map((color) => ({
              value: color === "any" ? "" : color,
              label: color.charAt(0).toUpperCase() + color.slice(1),
            }))}
            name="color"
            value={formValue.color}
          ></Form.Control>
          <Form.HelpText>
            Select the predominant color you want to search for in the images.
          </Form.HelpText>
        </Form.Group>
        <ButtonToolbar>
          <Button
            startIcon={<Icon as={FaSearch} />}
            appearance="primary"
            type="submit"
          >
            Search
          </Button>
          {selectedImagesIds.length > 0 && (
            <Button
              loading={loadingUpload}
              color="green"
              startIcon={<Icon as={FaUpload} />}
              appearance="primary"
              onClick={handleUploadPhotos}
            >
              Upload Selected Pictures
            </Button>
          )}
        </ButtonToolbar>
      </Form>
      <Divider />
      {loadingSearchPexels && <PlaceholderGrid active />}

      {dataSearchPexels && dataSearchPexels.photos.length > 0 && (
        <Form>
          <SelectTileGroup
            onSelectionChange={setSelectedImagesIds}
            selectedValues={selectedImagesIds}
          >
            <Stack wrap spacing={8} justifyContent="space-between">
              {dataSearchPexels.photos.map((photo) => (
                <Stack.Item key={photo.id}>
                  <SelectTile value={photo.id}>
                    <img src={photo.src.small} />
                  </SelectTile>
                </Stack.Item>
              ))}
            </Stack>
          </SelectTileGroup>
        </Form>
      )}
    </Panel>
  );
};

export default PexelsAssetPicker;

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes, useNavigate } from "react-router-dom";
import LoginPage from "./LoginPage";
import LoggedInUserRoutes from "./LoggedInUserRoutes";
import AuthenticatedContextProvider from "./AuthenticatedContextProvider";
import { Container, Footer } from "rsuite";

const AppRoutes: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate("/login");
      }
    }
  }, [isAuthenticated, isLoading]);

  return (
    <Container>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/*"
          element={
            <AuthenticatedContextProvider>
              <LoggedInUserRoutes />
            </AuthenticatedContextProvider>
          }
        />
      </Routes>
      <Footer>
        LVR.AI © 2023{" "}
        {new Date().getFullYear() === 2023
          ? ""
          : "- " + new Date().getFullYear()}
      </Footer>
    </Container>
  );
};

export default AppRoutes;

import React, { useEffect, useRef } from "react";

type TextareaProps = React.ComponentPropsWithRef<"textarea">;

const AutoResizingTextarea: React.FC<TextareaProps> = React.forwardRef(
  (props, ref) => {
    const { ...otherProps } = props;

    const innerRef = useRef<HTMLTextAreaElement | null>(null);
    const combinedRef = (ref as React.Ref<HTMLTextAreaElement>) || innerRef;

    const resizeTextArea = () => {
      if (typeof combinedRef !== "function" && combinedRef.current) {
        combinedRef.current.style.height = "0px";
        const scrollHeight = combinedRef.current.scrollHeight;
        combinedRef.current.style.height = scrollHeight + "px";
      }
    };

    useEffect(() => {
      window.addEventListener("resize", resizeTextArea);
      return () => {
        window.removeEventListener("resize", resizeTextArea);
      };
    }, [combinedRef]);

    useEffect(() => {
      resizeTextArea();
    }, [otherProps.value, combinedRef]);

    return <textarea className="rs-input" {...otherProps} ref={combinedRef} />;
  }
);

export default AutoResizingTextarea;

import React from "react";
import useAxios from "axios-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import {
  DeleteAssetDocument,
  GetLinkedInPostAssetsQuery,
} from "../../graphql/__generated__/graphql";
import { useNotificationWithMutation } from "../../hooks/useNotificationWithMutation";
import { DisplayError } from "../DisplayError";
import { Spinner } from "../Spinner";

interface AssetRendererProps {
  asset: NonNullable<GetLinkedInPostAssetsQuery["asset"][number]>;
  onRemove: (
    asset: NonNullable<GetLinkedInPostAssetsQuery["asset"][number]>
  ) => void;
}

const UploadedAssetRenderer: React.FC<AssetRendererProps> = ({
  asset,
  onRemove,
}) => {
  const [{ data, loading, error }] = useAxios(
    `${process.env.API_URL}/media-url/${asset.id}`
  );
  const [deleteAsset] = useNotificationWithMutation(
    useMutation(DeleteAssetDocument)
  );

  const renderMedia = (url: string) => {
    const mimetype = asset.mimetype;
    if(!mimetype || !asset.filename) {
      return null;
    }
    if (mimetype.startsWith("image/")) {
      return <img src={url} alt={asset.filename} />;
    } else if (mimetype.startsWith("video/")) {
      return <video src={url} controls />;
    } else if (mimetype.startsWith("audio/")) {
      return <audio src={url} controls />;
    } else {
      return null;
    }
  };

  if (loading || !data) {
    return <Spinner />;
  }

  const { url } = data;

  if (error) {
    return <DisplayError error={error} />;
  }

  const handleRemoveClick = async (event) => {
    event.preventDefault();
    await deleteAsset({
      variables: {
        id: asset.id,
      },
    });
    onRemove(asset);
  };

  return (
    <div className="relative group">
      {renderMedia(url)}
      <button
        onClick={handleRemoveClick}
        className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 
                   bg-white text-red-600 w-4 h-4 rounded-full flex items-center justify-center
                   opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out"
      >
        <FontAwesomeIcon icon={faTrashAlt} size="sm" />
      </button>
    </div>
  );
};

const PexelsAssetRenderer: React.FC<AssetRendererProps> = ({
  asset,
  onRemove,
}) => {
  const [deleteAsset] = useNotificationWithMutation(
    useMutation(DeleteAssetDocument)
  );
  const handleRemoveClick = async (event) => {
    event.preventDefault();
    await deleteAsset({
      variables: {
        id: asset.id,
      },
    });
    onRemove(asset);
  };
  return (
    <div className="relative group">
      <img src={asset.externalRaw?.src.medium} alt={asset.externalRaw?.alt}></img>
      <button
        onClick={handleRemoveClick}
        className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 
                   bg-white text-red-600 w-4 h-4 rounded-full flex items-center justify-center
                   opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out"
      >
        <FontAwesomeIcon icon={faTrashAlt} size="sm" />
      </button>
    </div>
  );
};

const AssetRenderer: React.FC<AssetRendererProps> = ({ asset, onRemove }) => {
  switch (asset.source) {
    case "UPLOAD":
      return <UploadedAssetRenderer asset={asset} onRemove={onRemove} />;
    case "PEXELS":
      return <PexelsAssetRenderer asset={asset} onRemove={onRemove} />;
    default:
      return null;
  }
};

export default AssetRenderer;

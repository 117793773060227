// Interview.tsx
import React, { useState } from "react";
import QuestionComponent from "./QuestionsComponent";
import {
  InterviewQuestionFields,
  InterviewResponses,
  InterviewQuestion,
} from "../types";
import { useMarketingUnit } from "./MarketingUnitContext";
import { Loading } from "./Loading";
import { omit } from "lodash";
import { Button, Divider, Form, Panel } from "rsuite";
import { NavLink } from "react-router-dom";

const questions: InterviewQuestion[] = [
  {
    field: "values",
    fieldName: "Values",
    question: "What values would you like your personal brand to represent?",
    type: "multiselect",
    options: [
      "safety",
      "environmental protection",
      "equality",
      "tradition",
      "favor",
      "gratitude",
      "honesty",
      "fairness",
      "responsibility",
      "transparency",
      "loyalty",
      "respect",
      "empathy",
      "collaboration",
      "autonomy",
      "creativity",
      "discovery",
      "passion",
      "ambition",
      "achievement",
      "perseverance",
      "innovation",
      "professionalism",
      "practicality",
      "efficiency",
      "long-term thinking",
      "pride",
      "harmony",
      "prosperity",
      "work-life balance",
      "community inclusion",
      "local support",
      "education",
      "development",
      "flexibility",
      "sustainability",
      "attentiveness",
      "truthfulness",
      "contentment",
      "optimism",
      "trust",
    ],
  },
  {
    field: "personalityTraits",
    fieldName: "Personality Traits",
    question:
      "What personality traits would you like to see characterize your personal brand?",
    type: "multiselect",
    options: [
      "openness to experience",
      "conscientiousness",
      "extroversion",
      "agreeableness",
      "neuroticism",
    ],
  },
  {
    field: "archetype",
    fieldName: "Archetype",
    question: "Which archetype best represents your personal brand?",
    type: "multiselect",
    options: [
      "hero",
      "sage",
      "rebel",
      "protector",
      "wizard",
      "innocent",
      "joker",
      "ruler",
      "mage",
      "lover",
      "explorer",
      "ordinary person",
    ],
  },
  {
    field: "personalBrandNarrative",
    fieldName: "Personal Brand Narrative",
    question:
      "What is your history as an expert in your field? What events, experiences or beliefs have contributed to the development of your personal brand?",
    type: "textarea",
  },
  {
    field: "brandPositioning",
    fieldName: "Brand Positioning",
    question: "What qualities distinguish you as an expert in your field?",
    type: "multiselect",
    options: [
      "In-depth industry knowledge",
      "Interpersonal skills",
      "Creativity and innovation",
      "Exceptional problem-solving skills",
      "Practical experience",
      "Inspiring achievements",
    ],
  },
  {
    field: "contentStrategy",
    fieldName: "Content Strategy",
    question:
      "What are the key aspects of your personal brand's content strategy and message?",
    type: "multiselect",
    options: [
      "Career tips and advice",
      "Career success stories",
      "Industry insights",
      "Research and achievement information",
      "Events and public speaking",
      "Collaboration with other experts",
    ],
  },
  {
    field: "skillsAndCompetencies",
    fieldName: "Skills and Competencies",
    question:
      "What are the key skills and competencies you would like your personal brand to promote?",
    type: "multiselect",
    options: [
      "Project management",
      "Data analysis",
      "Leadership and team management",
      "Sales and negotiation",
      "Marketing strategy",
      "Programming and software development",
    ],
  },
  {
    field: "communicationChannels",
    fieldName: "Communication Channels",
    question:
      "What communication channels are you going to use to promote your personal brand?",
    type: "multiselect",
    options: [
      "Personal blog",
      "Social media (LinkedIn, Twitter, Instagram, etc.)",
      "Podcasts",
      "Webinars and workshops",
      "Professional networks and industry organizations",
      "Presentations and conferences",
    ],
  },
  {
    field: "professionalGoals",
    fieldName: "Professional Goals",
    question:
      "What are the main professional goals you would like to achieve with your personal brand?",
    type: "multiselect",
    options: [
      "Build a strong professional network",
      "Attractiveness to potential employers or clients",
      "Get more orders or contracts",
      "Increase number of followers on social media",
      "Recognition as an expert in your field",
      "Support for your own business venture",
    ],
  },
];

export const questionsText = questions.map((q) => q.question);

const InterviewComponent: React.FC = () => {
  const { marketingUnit, onInterviewSubmit } = useMarketingUnit();

  if (!marketingUnit) {
    return <Loading />;
  }

  const { interview } = marketingUnit;
  const [interviewResponses, setInterviewResponses] =
    useState<InterviewResponses>(
      interview
        ? { ...omit(interview, "__typename", "id") }
        : {
            values: [],
            personalityTraits: [],
            archetype: [],
            personalBrandNarrative: "",
            brandPositioning: [],
            contentStrategy: [],
            skillsAndCompetencies: [],
            communicationChannels: [],
            professionalGoals: [],
          }
    );

  const handleChange = (field: InterviewQuestionFields, value: any) => {
    setInterviewResponses({
      ...interviewResponses,
      [field]: value,
    });
  };

  return (
    <Panel>
      <h2>Brand & Strategy Interview</h2>
      <p>
        Answer below questions to enable AI-generated guidelines. Once you fill
        it in and save you can use AI to generate brand & strategy.
      </p>
      <Divider />
      <Form
        fluid
        onSubmit={(isValid) => {
          if (isValid) {
            onInterviewSubmit(interviewResponses);
          }
        }}
        className="form box-dark"
      >
        {questions.map((question, index) => (
          <QuestionComponent
            key={index}
            question={question}
            value={interviewResponses[question.field]}
            onSelect={(value) => {
              handleChange(question.field, value);
            }}
          />
        ))}
        <Button appearance="primary" type="submit">
          Save
        </Button>
      </Form>
    </Panel>
  );
};

export default InterviewComponent;

// LinkedInAuthButton.tsx

import { faCheckCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxios from "axios-hooks";
import React, { useEffect } from "react";
import { DisplayError } from "./DisplayError";
import { Button, Panel, Stack } from "rsuite";
import { FaLinkedin } from "react-icons/fa";
import Icon from "@rsuite/icons/lib/Icon";
import { useUser } from "./InitialiseApp";

interface LinkedInAuthButtonProps {
  onAuthSuccess: () => void;
}

const LinkedInAuthButton: React.FC<LinkedInAuthButtonProps> = ({
  onAuthSuccess,
}) => {
  const { refetchUser } = useUser();

  const [
    {
      data: isLinkedInConnectedResponse,
      loading: loadingIsLinkedInConnected,
      error: errorIsLinkedInConnected,
    },
    refetchIsLinkedInConnected,
  ] = useAxios({
    url: `linkedin-sharing-connection-info`,
    baseURL: `${process.env.API_URL}/`,
    method: "post",
  });

  const [
    {
      data: storeLinkedInAccessTokenResponse,
      loading: loadingStoreLinkedInAccessToken,
      error: errorStoreLinkedInAccessToken,
    },
    executeStoreLinkedInAccessToken,
  ] = useAxios(
    {
      url: `store-linkedin-access-token`,
      baseURL: `${process.env.API_URL}/`,
      method: "post",
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    const refetch = async () => {
      if (
        !loadingStoreLinkedInAccessToken &&
        !!storeLinkedInAccessTokenResponse
      ) {
        await refetchIsLinkedInConnected();
        await refetchUser();
      }
    };
    refetch();
  }, [loadingStoreLinkedInAccessToken, storeLinkedInAccessTokenResponse]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        event.origin === `${process.env.API_URL}` &&
        event.data.type === "linkedin-auth-sharing"
      ) {
        const accessToken = event.data.accessToken;
        try {
          executeStoreLinkedInAccessToken({
            data: {
              app: "sharing",
              token: accessToken,
            },
          });
        } catch (error) {
          console.error("Error saving linkedin access token:", error);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [onAuthSuccess]);

  const handleClick = () => {
    if (!isLinkedInConnectedResponse.isConnected) {
      window.open(
        `${process.env.API_URL}/auth/linkedin-sharing`,
        "auth/linkedin-sharing",
        "width=600,height=800"
      );
    }
  };

  if (errorIsLinkedInConnected) {
    return <DisplayError error={errorIsLinkedInConnected} />;
  } else if (errorStoreLinkedInAccessToken) {
    return <DisplayError error={errorStoreLinkedInAccessToken} />;
  }

  return (
    <Panel
      bordered
      style={{
        display: "inline-block",
        textAlign: "center",
        width: "100%",
      }}
    >
      <Stack direction="column" spacing={8}>
        <Stack.Item>
          <Icon
            as={FaLinkedin}
            style={{ fontSize: "5em", color: "blue" }}
          ></Icon>
          <div>
            <h5>LinkedIn Sharing</h5>
            <p>
              <em className="text-sm">
                This is the most basic connection that allows you to: share your
                content as your personal profile on LinkedIn.
              </em>
            </p>
          </div>
        </Stack.Item>
        <Stack.Item>
          <Button
            appearance="primary"
            onClick={handleClick}
            color="green"
            disabled={
              loadingStoreLinkedInAccessToken ||
              loadingIsLinkedInConnected ||
              !isLinkedInConnectedResponse ||
              isLinkedInConnectedResponse.isConnected
            }
          >
            {loadingIsLinkedInConnected ? (
              <>
                <p>Checking...</p>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="animate-spin w-4"
                />
              </>
            ) : loadingStoreLinkedInAccessToken ? (
              <>
                <p>Connecting...</p>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="animate-spin w-4"
                />
              </>
            ) : (
              <>
                <p>
                  {isLinkedInConnectedResponse.isConnected ? (
                    <span>{`Connected as "${isLinkedInConnectedResponse.profile.localizedFirstName} ${isLinkedInConnectedResponse.profile.localizedLastName}"`}</span>
                  ) : (
                    "Connect"
                  )}
                </p>
                {isLinkedInConnectedResponse.isConnected && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-xl w-4"
                  />
                )}
              </>
            )}
          </Button>
        </Stack.Item>
      </Stack>
    </Panel>
  );
};

export default LinkedInAuthButton;

import { useMutation, useQuery } from "@apollo/client";
import EmojiPicker, {
  Emoji,
  EmojiClickData,
  EmojiStyle,
} from "emoji-picker-react";
import React, { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Panel, Schema } from "rsuite";
import ColorPicker from "rsuite-color-picker";
import {
  GetCampaignByIdDocument,
  InsertCampaignDocument,
  UpdateCampaignDocument
} from "../../graphql/__generated__/graphql";
import { useNotificationWithMutation } from "../../hooks/useNotificationWithMutation";
import { DisplayError } from "../DisplayError";
import InlineCampaign from "../InlineCampaign";
import { Loading } from "../Loading";
import { useMarketingUnit } from "../MarketingUnitContext";
import Textarea from "../TextArea";

const CreateCampaign: React.FC = () => {
  const { campaignId } = useParams();
  const [emoji, setEmoji] = useState<null | string | undefined>(null);
  const [name, setName] = useState<null | string | undefined>("");
  const [description, setDescription] = useState<null | string | undefined>("");
  const [color, setColor] = useState<null | string | undefined>("#00bcd4");
  const navigate = useNavigate();
  const { marketingUnit, marketingUnitQuery } = useMarketingUnit();
  const validationModel = Schema.Model({
    name: Schema.Types.StringType().isRequired("This field is required."),
    description: Schema.Types.StringType().isRequired("This field is required."),
  });

  const { data, loading, error, refetch } = useQuery(GetCampaignByIdDocument, {
    // @ts-ignore
    variables: { id: campaignId },
    skip: campaignId === undefined,
  });

  const messages = {
    loadingMessage: "Saving campaign",
    successMessage: "Saved campaign",
    errorMessage: "Failed to save campaign",
  };

  const [insertCampaign] = useNotificationWithMutation(
    useMutation(InsertCampaignDocument),
    messages
  );
  const [updateCampaign] = useNotificationWithMutation(
    useMutation(UpdateCampaignDocument),
    messages
  );

  useEffect(() => {
    if (campaignId !== undefined && data && data.campaign_by_pk) {
      const campaign = data.campaign_by_pk;
      setEmoji(campaign.emoji);
      setName(campaign.name);
      setDescription(campaign.description);
      setColor(campaign.color)
    } else if ([campaignId === undefined]) {
      setEmoji(null);
      setName("");
      setDescription("");
      setColor("#00bcd4")
    }
  }, [data, campaignId]);

  const handleSubmit = async (isValid) => {
    if (!isValid) return;
    if (campaignId === undefined) {
      const { data: newCampaign, errors } = await insertCampaign({
        variables: {
          object: { color, name, description, emoji, marketingUnitId: marketingUnit.id },
        },
      });
      if (!errors && newCampaign && newCampaign.insert_campaign_one) {
        navigate(`./${newCampaign.insert_campaign_one.id}`);
        marketingUnitQuery.refetch();
      }
    } else {
      await updateCampaign({
        variables: {
          id: campaignId,
          _set: { color, name, description, emoji },
        },
      });
      refetch();
    }
  };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    setEmoji(emojiData.unified);
  };

  if (campaignId !== undefined) {
    if (loading || !data) return <Loading />;
    if (error) return <DisplayError error={error} />;
  }

  return (
    <Panel>
      <h2>Campaign</h2>
      <InlineCampaign campaignProps={{ color, name, emoji }} />
      <Form onSubmit={handleSubmit} fluid>
        <Form.Group>
          <Form.ControlLabel>Name</Form.ControlLabel>
          <Form.Control
            value={name}
            onChange={setName}
            name="name"
          ></Form.Control>
          <Form.HelpText>
            Give this campaign a name to help you identify it.
          </Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Description</Form.ControlLabel>
          <Form.Control
            accepter={Textarea}
            value={description}
            onChange={setDescription}
            name="description"
          ></Form.Control>
          <Form.HelpText>
            Enter what this campaign is about. You can mention things like the
            topic, the length of the campain, its goal, target audience,
            preferred schedule, keywords, etc.{" "}
            <strong>
              This description will be used by AI to help you generate content
              automatically.
            </strong>
          </Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Color</Form.ControlLabel>
          <Form.Control
            accepter={ColorPicker}
            value={color}
            onChange={(value) => setColor(value.hex)}
            name="color"
          ></Form.Control>
          <Form.HelpText>
            You can give this campaign a color to help you identify it.
          </Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            <span>Icon</span> {emoji && <Emoji unified={emoji} />}
          </Form.ControlLabel>
          <EmojiPicker
            skinTonesDisabled={true}
            onEmojiClick={handleEmojiClick}
            autoFocusSearch={false}
            emojiStyle={EmojiStyle.NATIVE}
          />
          <Form.HelpText>
            Optionally add an emoji to make it even more distinguishable.
          </Form.HelpText>
        </Form.Group>
        <Button startIcon={<FaSave />} appearance="primary" type="submit">
          Save
        </Button>
      </Form>
    </Panel>
  );
};

export default CreateCampaign;

import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  FlexboxGrid,
  Form,
  Message,
  Panel,
  Radio,
  RadioGroup,
  Schema,
  SelectPicker,
  Stack,
  useToaster,
} from "rsuite";
import FlexboxGridItem from "rsuite/esm/FlexboxGrid/FlexboxGridItem";
import { ToastContainerProps } from "rsuite/esm/toaster/ToastContainer";
import { CreateMarketingUnitDocument } from "../graphql/__generated__/graphql";
import { useUser } from "./InitialiseApp";
import _ from "lodash";
import { getAuthorPicture } from "../utils";
const toastConfig: ToastContainerProps = {
  placement: "bottomEnd",
  duration: 2000,
};
export const MarketingUnitForm: React.FC = () => {
  const { user, refetchUser } = useUser();
  const navigate = useNavigate();
  const [authorsData, setAuthorsData] = useState<
    {
      value: number;
      label: string;
      pictureUrl: string | null;
    }[]
  >([]);

  useEffect(() => {
    if (user.linked_in_integration) {
      const authorsData = user.linked_in_integration.authors.map((author) => {
        const label =
          author.profileType === "person"
            ? `${author.externalRaw.localizedFirstName} ${author.externalRaw.localizedLastName}`
            : `${author.externalRaw.localizedName}`;
        let pictureUrl = getAuthorPicture(author);
        return {
          value: author.id,
          label,
          pictureUrl,
        };
      });
      setAuthorsData(authorsData);
    }
  }, [user]);

  const toaster = useToaster();
  const [unitType, setUnitType] = useState("");
  const [authorId, setAuthorId] = useState<number | null>(null);
  const [name, setName] = useState("");
  const [createMarketingUnit, { loading, error, data }] = useMutation(
    CreateMarketingUnitDocument
  );

  const model = Schema.Model({
    name: Schema.Types.StringType().isRequired("This field is required."),
    type: Schema.Types.StringType().isRequired("This field is required."),
    author: Schema.Types.NumberType().isRequired("This field is required."),
  });

  const handleSubmit = async () => {
    if (authorId === null) {
      toaster.push(
        <Message closable showIcon type="error">
          You have to set the author.
        </Message>,
        toastConfig
      );
      return;
    }

    const response = await createMarketingUnit({
      variables: {
        type: unitType,
        name,
        userId: user.id,
        authorId,
      },
    });
    await refetchUser();
    if (response.errors && response.errors.length > 0) {
      toaster.push(
        <Message closable showIcon type="error">
          {response.errors[0].message}
        </Message>
      );
    } else if (response.data && response.data.insert_marketing_unit_one) {
      navigate(`../${response.data.insert_marketing_unit_one.id}`);
    }
  };

  return (
    <FlexboxGrid justify="center">
      <FlexboxGridItem colspan={12}>
        <Panel>
          <div>
            <h2 className="text-center text-2xl font-semibold mb-2">
              Adding Marketing Unit
            </h2>
            <p className="mb-4 text-center">
              Marketing Units are the basic entities that you will use for
              marketing. It can be a company, a person, a product, or a service.
              Each marketing unit will have a brand, content and marketing
              strategy.
            </p>
          </div>
          <Panel bordered>
            <Form
              model={model}
              onSubmit={(isFormValid) => {
                if (isFormValid) handleSubmit();
              }}
            >
              <Form.Group controlId="name">
                <Form.ControlLabel>Name</Form.ControlLabel>
                <Form.Control
                  name="name"
                  onChange={(value) => setName(value)}
                  value={name}
                />
              </Form.Group>

              <Form.Group controlId="author">
                <Form.ControlLabel>Publish as</Form.ControlLabel>
                <Form.Control
                  name="author"
                  accepter={SelectPicker}
                  disabled={authorsData.length === 0}
                  data={authorsData}
                  cleanable={false}
                  onChange={(value) => {
                    setAuthorId(value);
                  }}
                  value={authorId}
                  renderMenuItem={(label, item) => {
                    return (
                      <Stack spacing={8} alignItems="center">
                        <Avatar src={item.pictureUrl}>
                          {label ? label[0] : "?"}
                        </Avatar>
                        <span>{label}</span>
                      </Stack>
                    );
                  }}
                ></Form.Control>
                {authorsData.length === 0 && (
                  <Message type="warning">
                    You must first connect LinkedIn to set a publishing author.
                  </Message>
                )}
              </Form.Group>
              <Form.Group controlId="type">
                <Form.ControlLabel>Type</Form.ControlLabel>
                <Form.Control
                  name="type"
                  accepter={RadioGroup}
                  onChange={(value) => setUnitType(value)}
                  value={unitType}
                >
                  <Radio value="person">Person</Radio>
                  <Radio value="company">Company</Radio>
                  <Radio value="product">Product</Radio>
                  <Radio value="service">Service</Radio>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Button appearance="primary" type="submit" loading={loading}>
                  Save
                </Button>
              </Form.Group>
              {error && (
                <Message closable type="error" showIcon header="Error">
                  {error.message}
                </Message>
              )}
            </Form>
          </Panel>
        </Panel>
      </FlexboxGridItem>
    </FlexboxGrid>
  );
};

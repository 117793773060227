import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { List, Stack } from "rsuite";
import { BraveSearchResultItem } from "../../types";
import { BsBookmark, BsFillBookmarkCheckFill } from "react-icons/bs";
import Icon from "@rsuite/icons/lib/Icon";
import { useResearchContext } from ".";

const SearchResult: React.FC<{ item: BraveSearchResultItem, isSelected: boolean }> = ({item, isSelected}) => {
  const [_isSelected, _setIsSelected] = React.useState(isSelected);
  const { onChangeSelectSearchResult } = useResearchContext();

  useEffect(() => {
    _setIsSelected(isSelected);
  }, [isSelected]);

  return (
    <List.Item>
      <Stack justifyContent="space-between" className="mb-1">
        <Stack spacing={5}>
          <Stack.Item>
            <img
              className="w-2 h-2"
              src={item.meta_url.favicon}
            ></img>
          </Stack.Item>
          <Stack.Item>
            <Link target="_new" to={item.url}>
              {item.title}
            </Link>
          </Stack.Item>
        </Stack>
        <Stack spacing={5}>
          <Stack.Item>{item.age}</Stack.Item>
          <Stack.Item>
            {_isSelected ? (
              <Icon
                className="text-green-500 text-lg cursor-pointer"
                as={BsFillBookmarkCheckFill}
                onClick={() => {
                  onChangeSelectSearchResult(item, false);
                }}
              ></Icon>
            ) : (
              <Icon
                className="text-orange-500 cursor-pointer text-lg"
                as={BsBookmark}
                onClick={() => {
                  onChangeSelectSearchResult(item, true);
                }}
              ></Icon>
            )}
          </Stack.Item>
        </Stack>
      </Stack>
      <Stack>
        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
      </Stack>
      {!!item.extra_snippets && item.extra_snippets.length > 0 && (
        <Stack
          className="styled-list mt-1"
          direction="column"
          spacing={5}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <strong>Basic summary</strong>
          <ul>
            {item.extra_snippets.map((snippet, index) => (
              <li key={`snippet-${index}`}>{snippet}</li>
            ))}
          </ul>
        </Stack>
      )}
    </List.Item>
  );
};

export default SearchResult;

// ApolloProviderWithAuth.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import createApolloClient from "../apollo-client";
import { Loading } from "./Loading";
import axios from "axios";
import InitializeApp from "./InitialiseApp";

interface IAuthenticatedContext {
  fetcher: ((input: RequestInfo | URL, init?: RequestInit | undefined) => Promise<Response>),
  token: string,
}

const AuthenticatedContext = createContext<IAuthenticatedContext>({
  fetcher: fetch,
  token: '',
});


export const useAuthenticatedContext = (): IAuthenticatedContext => {
  const context = useContext(AuthenticatedContext);
  if (!context) {
    throw new Error(
      "useAuthenticatedContext must be used within a AuthenticatedContextProvider"
    );
  }
  return context;
};

interface AuthenticatedContextProviderProps {
  children: React.ReactNode; // Declare children here
}

const AuthenticatedContextProvider: React.FC<
  AuthenticatedContextProviderProps
> = ({ children }) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, user: auth0User } = useAuth0();
  const [client, setClient] = useState<ApolloClient<any> | null>(null);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      setClient(createApolloClient(token));
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios.defaults.withCredentials = true;
      setToken(token);
    };

    if (!isLoading && isAuthenticated) fetchToken();
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);

  const fetcher = async (input: RequestInfo | URL, init?: RequestInit | undefined) => {
    const withDefaults = { ...init };
    withDefaults.credentials = "include";
    withDefaults.headers = {
      ...withDefaults.headers,
      Authorization: `Bearer ${token}`,
    };
    return fetch(input, withDefaults);
  }

  if (isLoading || !token || !client || !auth0User) {
    return <Loading />;
  }

  return (
    <ApolloProvider client={client}>
      <AuthenticatedContext.Provider value={{ fetcher, token }}>
        <InitializeApp auth0User={auth0User}>{children}</InitializeApp>
      </AuthenticatedContext.Provider>
    </ApolloProvider>
  );
};

export default AuthenticatedContextProvider;

import React, { useRef } from "react";
import {
  Dropdown,
  Popover,
  Whisper,
  WhisperInstance,
  Stack,
  Avatar,
  Header as RSuiteHeader,
  Nav,
} from "rsuite";
import { UserInReactContext } from "./InitialiseApp";
import ExitIcon from "@rsuite/icons/Exit";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Icon from "@rsuite/icons/lib/Icon";
import { FaCog, FaPlug, FaList } from "react-icons/fa";
import { BiGlassesAlt } from "react-icons/bi";
import CustomSidenavLink from "./CustomSideNavLink";
import CustomNavLink from "./CustomNavLink";

const Header: React.FC<{ user: UserInReactContext }> = ({ user }) => {
  const { logout } = useAuth0();
  const trigger = useRef<WhisperInstance>(null);
  return (
    <RSuiteHeader>
      <Stack justifyContent="space-between">
        <Stack spacing={8}>
          <NavLink to={"/"}>
            <img
              src="/public/logo.png"
              width={32}
              className="rounded-full"
              alt="lvr.ai logo"
            />
          </NavLink>
          <Nav className="mb-1 mt-1">
            <Nav.Item icon={<Icon as={FaList} />} as={CustomNavLink} to="/">
              Marketing Units
            </Nav.Item>
            <Nav.Item icon={<Icon as={BiGlassesAlt} />} as={CustomNavLink} to="/research">
              Research
            </Nav.Item>
          </Nav>
        </Stack>

        <Stack spacing={8} alignItems="center">
          <Nav>
            <Nav.Menu title="Settings" noCaret icon={<Icon as={FaCog} />}>
              <Nav.Item
                icon={<Icon as={FaPlug} />}
                as={CustomSidenavLink}
                to="/integrations"
              >
                Integrations
              </Nav.Item>
            </Nav.Menu>
          </Nav>
          <Whisper
            placement="bottomEnd"
            trigger="click"
            ref={trigger}
            speaker={({ onClose, left, top, className }: any, ref) => {
              const handleSelect = () => {
                onClose();
              };
              return (
                <Popover
                  ref={ref}
                  className={className}
                  style={{ left, top }}
                  full
                >
                  <Dropdown.Menu onSelect={handleSelect}>
                    <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                      <p>Signed in as</p>
                      <strong>{user.name}</strong>
                    </Dropdown.Item>
                    <Dropdown.Item
                      icon={<ExitIcon />}
                      onSelect={() =>
                        logout({
                          logoutParams: { returnTo: window.location.origin },
                        })
                      }
                    >
                      Sign out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Popover>
              );
            }}
          >
            <Avatar
              size="sm"
              circle
              className="cursor-pointer"
              src={user.picture || ""}
              alt={user.name || "Unnamed user"}
            />
          </Whisper>
        </Stack>
      </Stack>
    </RSuiteHeader>
  );
};

export default Header;

import Icon from "@rsuite/icons/lib/Icon";
import React, { useEffect, useState } from "react";
import { BiGlassesAlt, BiStop } from "react-icons/bi";
import { RxExternalLink, RxLink1, RxTrash } from "react-icons/rx";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import remarkGfm from "remark-gfm";
import {
  ButtonGroup,
  IconButton,
  Message,
  Panel,
  Progress,
  Stack,
  Tooltip,
  Whisper,
} from "rsuite";
import { useAuthenticatedContext } from "../AuthenticatedContextProvider";
import { AnalysisItemData } from "./Analyze";

export type NextAnalysisState = "start-analyzing" | "stop-analyzing" | "init";

export type AnalysisState =
  | "start-analyzing"
  | "stopped-analyzing"
  | "analysis-in-progress"
  | "finished-analyzing"
  | "error-analyzing"
  | "init";

const AnalysisItem: React.FC<{
  onRemove: () => void;
  onCheckedChange: (checked: boolean) => void;
  prompt: string;
  item: AnalysisItemData;
}> = ({ onRemove, prompt, item }) => {
  const { fetcher: fetch } = useAuthenticatedContext();
  const [documentsAnalyzed, setDocumentsAnalyzed] = React.useState(0);
  const [documentsToAnalyze, setDocumentsToAnalyze] = React.useState(0);
  const [body, setBody] = React.useState("");
  const [latestChunks, setLatestChunks] = React.useState<any[]>([]);
  const [error, setError] = React.useState<any>();
  const [state, setState] = React.useState<AnalysisState>("init");
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  useEffect(() => {
    if (latestChunks.length > 0) {
      let _body = body || "";
      for (const chunk of latestChunks) {
        switch (chunk.header) {
          case "llm-token":
            _body += chunk.body;
            break;
          case "llm-start":
            _body = "";
            break;
          case "chain-start":
            if (chunk.chain.id.includes("RefineDocumentsChain")) {
              setDocumentsToAnalyze(chunk.body.inputs.input_documents.length);
              setDocumentsAnalyzed(0);
            }
            break;
          case "chain-end":
            const { tags } = chunk.body;
            if (tags.includes("refine") || tags.includes("answer")) {
              setDocumentsAnalyzed(documentsAnalyzed + 1);
            }
            break;
        }
      }
      setBody(_body);
    }
  }, [latestChunks]);

  const startAnalyzing = async () => {
    const abort = new AbortController();
    setError(null);
    setState("start-analyzing");

    const url = `${
      process.env.API_URL
    }/research-web-page?prompt=${encodeURIComponent(
      prompt
    )}&url=${encodeURIComponent(item.searchResult.url)}`;

    const stream = await fetch(url, {
      signal: abort.signal,
    });
    if (!stream) return;
    const reader = stream.body?.getReader();
    if (!reader) return;
    let decoder = new TextDecoder();
    setState("analysis-in-progress");

    setAbortController(abort);
    let partialChunk = "";
    await reader
      .read()
      .then(function processText({ done, value }) {
        if (done) {
          setState("finished-analyzing");
          return;
        }
        const decoded = decoder.decode(value).replace(/,$/, "");
        partialChunk += decoded;
        const decodedArray = `[${partialChunk}]`;
        // Chrome has a weird behavior that with slow network it splits chunks into subchunks. 
        // Code must check if current partial chunk can parse as JSON.
        try {
          const chunks = JSON.parse(decodedArray);
          setLatestChunks(chunks);
          partialChunk = "";
        } catch {
          // Continue
        }
        return reader.read().then(processText);
      })
      .catch((error) => {
        if (abort.signal.aborted) {
          setState("stopped-analyzing");
        } else {
          console.error(error);
          setError(error);
          abort.abort();
          setState("error-analyzing");
        }
      });

    setAbortController(null);
  };

  return (
    <Panel
      className="compact research-item"
      header={
        <Stack direction="column" alignItems="stretch" spacing={5}>
          <Stack.Item>
            <Stack justifyContent="space-between">
              <Stack spacing={5}>
                <Stack.Item>
                  {item.searchResult.meta_url.favicon ? (
                    <img
                      className="w-2 h-2"
                      src={item.searchResult.meta_url.favicon}
                    ></img>
                  ) : (
                    <Icon as={RxLink1}></Icon>
                  )}{" "}
                </Stack.Item>
                <Stack.Item>{item.searchResult.title}</Stack.Item>
              </Stack>
              <Stack className="mr-3" spacing={5}>
                <ButtonGroup>
                  {state === "analysis-in-progress" ? (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        abortController && abortController.abort();
                      }}
                      icon={
                        <Icon className="text-orange-500" as={BiStop}></Icon>
                      }
                    ></IconButton>
                  ) : (
                    <Whisper
                      trigger="hover"
                      placement="top"
                      controlId={`control-id-top`}
                      speaker={
                        <Tooltip>
                          Performs thorough analysis. Make sure to enter the
                          prompt first.
                        </Tooltip>
                      }
                    >
                      <IconButton
                        disabled={prompt === ""}
                        loading={state === "start-analyzing"}
                        onClick={(e) => {
                          e.stopPropagation();
                          startAnalyzing();
                        }}
                        icon={
                          <Icon
                            className="text-green-500"
                            as={BiGlassesAlt}
                          ></Icon>
                        }
                      ></IconButton>
                    </Whisper>
                  )}

                  <IconButton
                    onClick={(e) => e.stopPropagation()}
                    as={Link}
                    target="_new"
                    to={item.searchResult.url}
                    icon={
                      <Icon
                        className="text-blue-500"
                        as={RxExternalLink}
                      ></Icon>
                    }
                  ></IconButton>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      onRemove();
                    }}
                    icon={<Icon className="text-red-500" as={RxTrash} />}
                  ></IconButton>
                </ButtonGroup>
              </Stack>
            </Stack>
          </Stack.Item>
          {state !== "init" && state !== "start-analyzing" && documentsToAnalyze !== 0 && (
            <Stack.Item>
              <Progress.Line
                percent={Math.ceil(
                  (documentsAnalyzed * 100) / documentsToAnalyze
                )}
                status={
                  state === "finished-analyzing"
                    ? "success"
                    : state === "error-analyzing"
                    ? "fail"
                    : "active"
                }
              />
            </Stack.Item>
          )}
        </Stack>
      }
      key={item.searchResult.url}
    >
      {(state === "finished-analyzing" ||
        state === "stopped-analyzing" ||
        state === "analysis-in-progress") && (
        <div className="border-solid border-gray-300 border rounded-md bg-gray-50 p-1">
          <h4>Thorough analysis</h4>
          <ReactMarkdown
            className="markdown-viewer"
            remarkPlugins={[remarkGfm]}
          >
            {body || "*Analyzing*"}
          </ReactMarkdown>
        </div>
      )}
      {!!item.searchResult.extra_snippets &&
        item.searchResult.extra_snippets.length > 0 && (
          <Stack
            className="styled-list mt-1"
            direction="column"
            spacing={5}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <h4>Basic summary</h4>
            <ul>
              {item.searchResult.extra_snippets.map((snippet, index) => (
                <li key={`snippet-${index}`}>{snippet}</li>
              ))}
            </ul>
          </Stack>
        )}
      {state === "error-analyzing" && (
        <Message type="error">
          {Array.isArray(error) ? error[0] : String(error)}
        </Message>
      )}
    </Panel>
  );
};

export default AnalysisItem;

import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  ButtonToolbar,
  InputPicker,
  IconButton,
  Schema,
  useToaster,
  Message,
  Stack,
} from "rsuite";
import { LinkedInPostContentById } from "../../types";
import Icon from "@rsuite/icons/lib/Icon";
import { FaMagic, FaMinusCircle, FaPlusCircle, FaSave } from "react-icons/fa";
import { ToastContainerProps } from "rsuite/esm/toaster/ToastContainer";
import { UpsertLinkedInPollDocument } from "../../graphql/__generated__/graphql";
import { useNotificationWithMutation } from "../../hooks/useNotificationWithMutation";
import { useMutation } from "@apollo/client";
import { useLinkedInPost } from "../LinkedInPostContent";
import useAxios from "axios-hooks";

interface PollSettings {
  voteSelectionType: "SINGLE_VOTE";
  duration: "ONE_DAY" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
}

interface PollOption {
  text: string;
}

interface LinkedInPoll {
  id: number;
  question: string;
  settings: PollSettings;
  options: PollOption[];
}

interface LinkedInPollFormProps {
  content: LinkedInPostContentById;
}

const validationModel = Schema.Model({
  question: Schema.Types.StringType()
    .maxLength(140, "Question must be 140 characters or less")
    .isRequired("This field is required."),
  duration: Schema.Types.StringType().isRequired("Duration is required."),
});

const optionRule = Schema.Types.StringType()
  .maxLength(40, "Option must be 40 characters or less")
  .isRequired("Option is required.");

function optionsToFormInputs(options: PollOption[]) {
  return options.reduce((prev, curr, index) => {
    prev[`option-${index}`] = curr.text;
    return prev;
  }, {});
}

const LinkedInPollForm: React.FC<LinkedInPollFormProps> = ({ content }) => {
  const options: any[] = content.poll?.options || [{ text: "" }];

  const initFormValue = {
    question: content.poll?.question || "",
    // settings: content.poll?.settings || {
    // voteSelectionType: "SINGLE_VOTE",
    duration: "ONE_DAY",
    // },
    ...optionsToFormInputs(options),
  };

  const [formValue, setFormValue] = React.useState(initFormValue);
  const { onPollUpsert } = useLinkedInPost();
  const toastConfig: ToastContainerProps = {
    placement: "bottomEnd",
    duration: 2000,
  };
  const toaster = useToaster();
  const [pollInput, setPollInput] = useState<Partial<LinkedInPoll>>(
    content.poll || {}
  );
  const [pollOptions, setPollOptions] = useState<PollOption[]>(
    content.poll?.options || [{ text: "" }]
  );
  const [upsertPoll] = useNotificationWithMutation(
    useMutation(UpsertLinkedInPollDocument),
    {
      loadingMessage: "Saving poll",
      successMessage: "Saved poll",
    }
  );

  const [
    { loading: generatingPoll, data: generatedPoll, error: errorGenerating },
    generatePoll,
  ] = useAxios(
    {
      url: `${process.env.API_URL}/generate-poll/${content.id}`,
      method: "get",
    },
    { manual: true }
  );

  useEffect(() => {
    if (generatedPoll && generatedPoll.poll) {
      const options = generatedPoll.poll.options.map((option) => ({
        text: option,
      }));
      setPollOptions(options);
      setFormValue({
        question: generatedPoll.poll.question,
        duration: generatedPoll.poll.duration,
        ...optionsToFormInputs(options),
      });
    }
    if (errorGenerating) {
      toaster.push(
        <Message closable showIcon type="error">
          {errorGenerating.message}
        </Message>,
        toastConfig
      );
    }
  }, [generatedPoll, errorGenerating]);

  useEffect(() => {
    if (content.poll) {
      setFormValue({
        question: content.poll.question,
        duration: content.poll.settings.duration,
        ...optionsToFormInputs(content.poll.options),
      });
      setPollInput(content.poll);
      setPollOptions(content.poll.options);
    }
  }, [content]);

  const handleInputChange = (value: any, name: string) => {
    setFormValue({ ...formValue, [name]: value });
  };

  const handleOptionChange = (value: string, index: number) => {
    const newOptions = [...pollOptions];
    newOptions[index].text = value;
    setPollOptions(newOptions);
    setFormValue({ ...formValue, [`option-${index}`]: value });
  };

  const addOption = () => {
    if (pollOptions.length < 4) {
      setFormValue({ ...formValue, [`option-${pollOptions.length}`]: "" });
      setPollOptions([...pollOptions, { text: "" }]);
    } else {
      toaster.push(
        <Message closable showIcon type="warning">
          There can be maximum 4 options.
        </Message>,
        toastConfig
      );
    }
  };

  const removeOption = (index: number) => {
    const newOptions = [...pollOptions];
    for (let i = 0; i < newOptions.length; i++) {
      delete formValue[`option-${i}`];
    }
    newOptions.splice(index, 1);
    setFormValue({ ...formValue, ...optionsToFormInputs(newOptions) });
    setPollOptions(newOptions);
  };

  const handleGeneratePoll = async () => {
    generatePoll();
  };

  const handleSubmit = async (isValid) => {
    if (!isValid) {
      return;
    }
    // Implement your save logic here
    const { data, errors } = await upsertPoll({
      variables: {
        object: {
          id: pollInput.id,
          question: formValue.question,
          settings: {
            voteSelectionType: "SINGLE_VOTE",
            duration: formValue.duration,
          },
          options: pollOptions,
        },
      },
    });
    if (!errors && data && data.insert_linked_in_poll_one && onPollUpsert) {
      await onPollUpsert(data.insert_linked_in_poll_one.id);
    }
  };

  return (
    <>
      <h2>LinkedIn Poll</h2>
      <Form
        formValue={formValue}
        onSubmit={handleSubmit}
        fluid
        model={validationModel}
      >
        <Form.Group>
          <Form.ControlLabel>Question</Form.ControlLabel>
          <Form.Control
            name="question"
            // value={pollInput.question || ""}
            onChange={(value) => handleInputChange(value, "question")}
          />
          <Form.HelpText>
            Insert what you want to ask. Max 140 characters.
          </Form.HelpText>
        </Form.Group>

        <Form.Group>
          <Form.ControlLabel>Poll duration</Form.ControlLabel>
          <Form.Control
            name="duration"
            accepter={InputPicker}
            data={[
              { label: "One Day", value: "ONE_DAY" },
              { label: "Three Days", value: "THREE_DAYS" },
              { label: "Seven Days", value: "SEVEN_DAYS" },
              { label: "Fourteen Days", value: "FOURTEEN_DAYS" },
            ]}
            onChange={(value) => handleInputChange(value, "duration")}
          />
        </Form.Group>

        <Form.Group>
          <Form.ControlLabel>Options</Form.ControlLabel>
          <Stack direction="column" alignItems="stretch" spacing={5}>
            {pollOptions.map((_, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Form.Control
                  rule={optionRule}
                  name={`option-${index}`}
                  maxLength={40}
                  onChange={(value) =>
                    handleOptionChange(value as string, index)
                  }
                />
                <IconButton
                  icon={<Icon as={FaMinusCircle} />}
                  color="red"
                  circle
                  size="sm"
                  onClick={() => removeOption(index)}
                  style={{ marginLeft: 10 }}
                />
              </div>
            ))}
            <Button
              startIcon={<FaPlusCircle />}
              onClick={addOption}
              appearance="primary"
              color="green"
            >
              Add Option
            </Button>
          </Stack>
          <Form.HelpText>
            Add options to choose from to answer your question. Max 4 options,
            each option max 40 characters.
          </Form.HelpText>
        </Form.Group>

        <ButtonToolbar>
          <Button startIcon={<FaSave />} appearance="primary" type="submit">
            Save Poll
          </Button>
          <Button
            startIcon={<FaMagic />}
            appearance="primary"
            loading={generatingPoll}
            disabled={generatingPoll}
            onClick={handleGeneratePoll}
            color="green"
          >
            Generate Poll
          </Button>
        </ButtonToolbar>
      </Form>
    </>
  );
};

export default LinkedInPollForm;

import { ApolloQueryResult } from "@apollo/client";
import Icon from "@rsuite/icons/lib/Icon";
import React from "react";
import { FaUpload } from "react-icons/fa";
import { SiPexels } from "react-icons/si";
import { Nav } from "rsuite";
import { GetLinkedInPostAssetsQuery } from "../../graphql/__generated__/graphql";
import { LinkedInPostContentById } from "../../types";
import LinkedInPostAssetsInput from "../LinkedInPostAssetsInput";
import PexelsAssetPicker from "../PexelsAssetPicker";

const MediaAssetPicker: React.FC<{
  linkedInPostContent: LinkedInPostContentById;
  refetchAssets: (
    variables?:
      | Partial<{
          id: number;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<GetLinkedInPostAssetsQuery>>;
}> = ({ linkedInPostContent, refetchAssets }) => {
  const [active, setActive] = React.useState("pexels");

  const tabs = {
    ai: "AI",
    pexels: <PexelsAssetPicker />,
    adobe: "Adobe",
    upload: (
      <LinkedInPostAssetsInput
        linkedInPostContent={linkedInPostContent}
        refetchAssets={refetchAssets}
      />
    ),
  };

  return (
    <>
      <h2>Media Asset Picker</h2>
      <Nav activeKey={active} onSelect={setActive} appearance="tabs">
        {/* <Nav.Item eventKey="ai" icon={<Icon as={FaMagic} />}>
          AI
        </Nav.Item> */}
        <Nav.Item eventKey="pexels" icon={<Icon as={SiPexels} />}>
          Pexels
        </Nav.Item>
        {/* <Nav.Item eventKey="adobe" icon={<Icon as={SiAdobe} />}>
          Adobe
        </Nav.Item> */}
        <Nav.Item eventKey="upload" icon={<Icon as={FaUpload} />}>
          Upload
        </Nav.Item>
      </Nav>
      {tabs[active]}
    </>
  );
};

export default MediaAssetPicker;

import React from "react";
import "./styles/index.css";
import "./styles/index.less";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("No root element found");
}

const root = createRoot(rootElement);
root.render(
  <Auth0Provider
    domain={process.env.AUTH0_DOMAIN as string}
    clientId={process.env.AUTH0_CLIENTID as string}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.AUTH0_AUDIENCE as string,
    }}
  >
    <ToastContainer />
    <App />
  </Auth0Provider>
);

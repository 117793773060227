import Icon from "@rsuite/icons/lib/Icon";
import React, { useEffect } from "react";
import { BiGlassesAlt } from "react-icons/bi";
import { RxPlusCircled } from "react-icons/rx";
import {
  Divider,
  Form,
  Input,
  InputGroup,
  Message,
  PanelGroup,
  Schema,
  Stack
} from "rsuite";
import FormControl from "rsuite/esm/FormControl";
import FormHelpText from "rsuite/esm/FormHelpText";
import { useResearchContext } from ".";
import { BraveSearchResultItem } from "../../types";
import AnalysisItem, { AnalysisState } from "./AnalysisItem";

type AnalyzeProps = {
  searchResults: BraveSearchResultItem[];
};

export type AnalysisItemData = {
  searchResult: BraveSearchResultItem;
  checked: boolean;
  state: AnalysisState;
  abortController?: AbortController;
  latestChunks: any[];
};

const Analyze: React.FC<AnalyzeProps> = ({ searchResults }) => {
  const { onChangeSelectSearchResult } = useResearchContext();
  const [resourceUrl, setResourceUrl] = React.useState("");
  const [prompt, setPrompt] = React.useState("");
  const [analysisItems, setAnalysisItems] = React.useState<AnalysisItemData[]>(
    searchResults.map((item) => ({
      searchResult: item,
      checked: false,
      state: "init",
      latestChunks: [],
    }))
  );

  useEffect(() => {
    const newItems: AnalysisItemData[] = searchResults
      .filter(
        (sr) => !analysisItems.find((ai) => ai.searchResult.url === sr.url)
      )
      .map((item) => ({
        searchResult: item,
        checked: false,
        state: "init",
        latestChunks: [],
      }));
    const keptItems = analysisItems.filter((ai) =>
      searchResults.find((sr) => ai.searchResult.url === sr.url)
    );

    setAnalysisItems([...keptItems, ...newItems]);
  }, [searchResults]);

  const handleAddResource = () => {
    if (!resourceUrl) return;
    if (analysisItems.some((item) => item.searchResult.url === resourceUrl))
      return;
    let title = resourceUrl;
    if (resourceUrl.length > 80) {
      // cut the middle and insert ... inside
      const surplus = resourceUrl.length - 80;
      title =
        resourceUrl.slice(0, resourceUrl.length / 2 - surplus / 2) +
        "..." +
        resourceUrl.slice(resourceUrl.length / 2 + surplus / 2);
    }
    setAnalysisItems([
      {
        checked: false,
        latestChunks: [],
        state: "init",
        searchResult: {
          age: "?",
          description: "",
          meta_url: {
            favicon: "",
          },
          url: resourceUrl,
          title,
        },
      },
      ...analysisItems,
    ]);
    setResourceUrl("");
  };

  const urlRule = Schema.Types.StringType().isURL("Please enter a valid URL.");

  return (
    <div>
      <>
        <Stack direction="column" spacing={16} alignItems="stretch">
          <Message showIcon type="info" closable>
            Most documents have a basic summary. To dive deeper, click the{" "}
            <Icon className="text-green-500" as={BiGlassesAlt}></Icon> button.
            This will prompt the AI to analyze the document section by section,
            refining its answers. You'll see these updated answers as they come.
            You can wait for the analysis to finish (indicated by the progress
            bar) or cancel at any point.{" "}
            <strong>
              Note: A detailed analysis can take several minutes and uses many
              AI tokens.
            </strong>
          </Message>
          <Form.Group>
            <Input
              as="textarea"
              value={prompt}
              onChange={setPrompt}
              placeholder="What do you want to focus on when researching?"
            ></Input>
            <FormHelpText>
              Enter a subject matter, a question or a prompt that will be used
              by default for all below documents.
            </FormHelpText>
          </Form.Group>
        </Stack>
        <Divider />
        <Stack direction="column" spacing={16} alignItems="stretch">
          <Form
            onSubmit={(isValid) => {
              if (!isValid) return;
              handleAddResource();
            }}
          >
            <InputGroup>
              <FormControl
                name="resource-url"
                accepter={Input}
                rule={urlRule}
                value={resourceUrl}
                onChange={setResourceUrl}
                placeholder="Resource URL"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleAddResource();
                  }
                }}
              />
              <InputGroup.Button
                appearance="primary"
                type="submit"
                endIcon={<Icon as={RxPlusCircled}></Icon>}
              >
                Add a Resource
              </InputGroup.Button>
            </InputGroup>
          </Form>
          <PanelGroup accordion bordered={analysisItems.length > 0}>
            {analysisItems.map((item) => (
              <AnalysisItem
                key={item.searchResult.url}
                item={item}
                prompt={prompt}
                onRemove={() =>
                  onChangeSelectSearchResult(item.searchResult, false)
                }
                onCheckedChange={(checked) => {
                  setAnalysisItems(
                    analysisItems.map((analysisItem) =>
                      analysisItem.searchResult.url === item.searchResult.url
                        ? { ...analysisItem, checked }
                        : analysisItem
                    )
                  );
                }}
              ></AnalysisItem>
            ))}
          </PanelGroup>
        </Stack>
      </>
    </div>
  );
};

export default Analyze;

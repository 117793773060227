import React from "react";
import { NavLink } from "react-router-dom";

const CustomSidenavLink = React.forwardRef<any, any>(
  ({ to, children, className, ...rest }, ref) => (
    <NavLink
      ref={ref}
      to={to}
      end
      className={({ isActive }) =>
        isActive ? "rs-sidenav-item-active " + className : className
      }
      {...rest}
    >
      {children}
    </NavLink>
  )
);

export default CustomSidenavLink;

// BrandDescription.tsx
import React, { useState } from "react";
import { useMarketingUnit } from "./MarketingUnitContext";
import { BrandData } from "../types";
import useAxios from "axios-hooks";
import { omit } from "lodash";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Divider,
  Drawer,
  Form,
  Input,
  Message,
  Panel,
  Tooltip,
  Whisper,
  useToaster,
} from "rsuite";
import { useMutation } from "@apollo/client";
import {
  UpdateMarketingUnitDocument,
  UpsertBrandDocument,
} from "../graphql/__generated__/graphql";
import { ToastContainerProps } from "rsuite/esm/toaster/ToastContainer";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import Interview from "./Interview";
import Textarea from "./TextArea";
import { FaMagic, FaMicrophone, FaPen } from "react-icons/fa";
import { BsJournalText } from "react-icons/bs";

const featuresToLabels: Record<keyof BrandData, string> = {
  valuesAndGoal: "Values and Goal",
  archetypeAndPersonality: "Archetype and Personality",
  narrative: "Narrative",
  targetAudience: "Target Audience",
  positioning: "Positioning",
  visualIdentity: "Visual Identity",
  voiceAndTone: "Voice and Tone",
  skillsAndCompetencies: "Skills and Competencies",
  contentStrategy: "Content Strategy",
  communicationChannels: "Communication Channels",
  professionalGoals: "Professional Goals",
};

const brandGuidelineFeatures: Array<keyof BrandData> = [
  "valuesAndGoal",
  "archetypeAndPersonality",
  "narrative",
  "targetAudience",
  "positioning",
  "visualIdentity",
  "voiceAndTone",
  "skillsAndCompetencies",
  "contentStrategy",
  "communicationChannels",
  "professionalGoals",
];

const BrandGuidelineComponent: React.FC = () => {
  const toastConfig: ToastContainerProps = {
    placement: "topCenter",
    duration: 5000,
  };
  const toaster = useToaster();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { marketingUnit, marketingUnitQuery } = useMarketingUnit();
  const { brand: storedBrand } = marketingUnit;
  const [brandForm, setBrandForm] = useState(
    _.omit(storedBrand, "__typename") || {}
  );
  const [{ loading }, generateBrandGuideline] = useAxios(
    {
      url: `${process.env.API_URL}/generate-brand-guideline`,
      method: "post",
    },
    { manual: true }
  );

  const [upsertBrand] = useMutation(UpsertBrandDocument);
  const [updateMarketingUnitDocument] = useMutation(
    UpdateMarketingUnitDocument
  );

  const toastMessage = (type, message) => {
    toaster.push(
      <Message closable showIcon type={type}>
        {message}
      </Message>,
      toastConfig
    );
  };

  const handleGenerateBrandGuideline = async () => {
    const { data, status, statusText } = await generateBrandGuideline({
      data: {
        interviewResponses: omit(marketingUnit.interview, "id", "__typename"),
      },
    });
    if (status !== 200) {
      toastMessage("error", statusText);
      return;
    }
    setBrandForm(data);
  };

  const save = async (brand: any) => {
    const { data: upsertBrandData, errors: upsertErrors } = await upsertBrand({
      variables: {
        object: brand,
      },
    });
    if (upsertErrors && upsertErrors.length > 0) {
      toastMessage("error", upsertErrors[0].message);
      return;
    }
    if (upsertBrandData && upsertBrandData.insert_brand_one) {
      const { errors: updateErrors } = await updateMarketingUnitDocument({
        variables: {
          id: marketingUnit.id,
          _set: {
            brandId: upsertBrandData.insert_brand_one.id,
          },
        },
      });
      if (updateErrors && updateErrors.length > 0) {
        toastMessage("error", updateErrors[0].message);
        return;
      }
    }
    await marketingUnitQuery.refetch();
    toastMessage("success", "Brand & Strategy saved");
  };

  const tooltip = (
    <Tooltip>Guideline generation requires an interview.</Tooltip>
  );

  return (
    <Panel>
      <h1>Brand & Strategy</h1>
      <div className="mb-4 mt-3">
        <p>
          Edit or generate this simple guideline to inform AI about your brand
          and marketing strategy. This information will be used by AI to
          generate content consistently and inline with your brand.
        </p>
        <p>
          You can either fill your guidelines manually or generate them with AI.
          If you want to generate them with AI, you will need to take the{" "}
          <a className="cursor-pointer" onClick={() => setOpenDrawer(true)}>
            interview
          </a>{" "}
          first.
        </p>
      </div>
      <ButtonToolbar>
        <Whisper
          placement="top"
          controlId="control-id-hover"
          trigger="hover"
          speaker={tooltip}
        >
          <Button
            startIcon={<FaMagic />}
            disabled={!marketingUnit.interview}
            loading={loading}
            appearance="primary"
            onClick={handleGenerateBrandGuideline}
            className="btn btn-primary"
          >
            Generate Guideline with AI
          </Button>
        </Whisper>
        <Button
          startIcon={<BsJournalText />}
          appearance="primary"
          onClick={() => setOpenDrawer(true)}
          className="btn btn-primary"
        >
          Open Interview
        </Button>
      </ButtonToolbar>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Drawer.Body>
          <Interview />
        </Drawer.Body>
      </Drawer>
      <Divider />
      <h3>Guideline</h3>
      <Form
        fluid
        onSubmit={(isValid) => {
          if (isValid) {
            save(brandForm);
          }
        }}
      >
        {brandGuidelineFeatures.map((brandFeature) => (
          <Form.Group controlId={brandFeature} key={brandFeature}>
            <Form.ControlLabel>
              {featuresToLabels[brandFeature]}
            </Form.ControlLabel>
            <Form.Control
              accepter={Textarea}
              name={brandFeature}
              value={brandForm[brandFeature]}
              onChange={(value) =>
                setBrandForm({ ...brandForm, [brandFeature]: value })
              }
            />
          </Form.Group>
        ))}
        <ButtonToolbar>
          <Button loading={loading} appearance="primary" type="submit">
            Save
          </Button>
        </ButtonToolbar>
      </Form>
    </Panel>
  );
};

export default BrandGuidelineComponent;

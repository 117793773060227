import {
  Brand,
  GetCampaignByIdQuery,
  GetLinkedInPostByIdQuery,
  Interview,
} from "./graphql/__generated__/graphql";

type InterviewModelSpecificFields =
  | "id"
  | "__typename"
  | "marketingUnitId"
  | "marketing_unit";
export type InterviewQuestionFields = keyof Omit<
  Interview,
  InterviewModelSpecificFields
>;
export type InterviewResponses = Omit<Interview, InterviewModelSpecificFields>;
export type BrandData = Omit<
  Brand,
  | "id"
  | "__typename"
  | "marketing_unit"
  | "marketingUnitId"
  | "marketing_unit_aggregate"
>;

export interface InterviewQuestion {
  field: InterviewQuestionFields;
  fieldName: string;
  question: string;
  type: "checkbox" | "radio" | "textarea" | "multiselect" | "text";
  options?: string[];
}

export type BraveSearchResultItem = {
  age: string;
  description: string;
  extra_snippets?: string[];
  title: string;
  url: string;
  meta_url: {
    favicon: string;
  };
};

export type ContentPlanItemMedia = {
  id: string;
  filename: string;
  mimetype: string;
  url?: string;
};

export type LinkedInPostContentById = NonNullable<
  GetLinkedInPostByIdQuery["linked_in_post_content_by_pk"]
>;

export type CampaignById = NonNullable<GetCampaignByIdQuery["campaign_by_pk"]>;

export enum ContentStatus {
  DRAFT = "DRAFT",
  FINAL = "FINAL",
  PUBLICATION_SCHEDULED = "PUBLICATION_SCHEDULED",
  PUBLISHED = "PUBLISHED",
}

export enum PublicationState {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  UPLOADING_ASSETS = "UPLOADING_ASSETS",
  ASSETS_UPLOADED = "ASSETS_UPLOADED",
  SCHEDULED = "SCHEDULED",
  DELETED = "DELETED",
  FAILED_TO_PUBLISH = "FAILED_TO_PUBLISH",
}

export enum LinkedInPostContentType {
  Text = "Text",
  Images = "Images",
  // Videos = "Videos",
  // Documents = "Documents",
  // Article = "Article",
  // Carousels = "Carousels",
  // MultiImage = "MultiImage",
  Poll = "Poll",
}

// export type ContentPlanItemInput = {
//   id?: string;
//   title: string;
//   description: string;
//   contentType: string;
//   dateTime: string;
//   status: "idea" | "scheduled" | "published";
//   content: string;
//   feedback: string;
//   mediaFiles: ContentPlanItemMedia[];
// };

// export type ContentPlanItem = {
//   synced: boolean;
// } & ContentPlanItemInput;

import React, { createContext, useEffect, useState } from "react";

export const SelectTileContext = createContext<{
  selectedValues: string[];
  onSelectionChange: (value: string) => void;
}>({
  selectedValues: [],
  onSelectionChange: () => {},
});

const SelectTileGroup: React.FC<{
  children: React.ReactNode;
  selectedValues?: string[];
  onSelectionChange?: (value: string[]) => void;
}> = ({ children, selectedValues, onSelectionChange }) => {
  const [_selectedValues, _setSelectedValues] = useState(selectedValues || []);

  useEffect(() => {
    if (selectedValues) {
      _setSelectedValues(selectedValues);
    }
  }, [selectedValues]);

  return (
    <SelectTileContext.Provider
      value={{
        selectedValues: _selectedValues,
        onSelectionChange: (value) => {
          const updatedSelection = _selectedValues.includes(value)
            ? _selectedValues.filter((selected) => selected !== value)
            : [..._selectedValues, value];

          _setSelectedValues(updatedSelection);
          onSelectionChange && onSelectionChange(updatedSelection);
        },
      }}
    >
      <div className="select-tile-group">{children}</div>
    </SelectTileContext.Provider>
  );
};

export default SelectTileGroup;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./components/AppRoutes";
import { CustomProvider } from 'rsuite'

// App component
const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <CustomProvider theme="light">
          <AppRoutes />
        </CustomProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;

import React from "react";
import { UserInReactContext } from "../InitialiseApp";
import {
  Avatar,
  Button,
  Divider,
  FlexboxGrid,
  Panel,
  Stack,
  Table,
} from "rsuite";
import { NavLink } from "react-router-dom";
import FlexboxGridItem from "rsuite/esm/FlexboxGrid/FlexboxGridItem";
import { getAuthorPicture } from "../../utils";

const HomePage: React.FC<{ user: UserInReactContext }> = ({ user }) => {
  return (
    <FlexboxGrid justify="center">
      <FlexboxGridItem colspan={12}>
        <Panel bordered>
          <p>
            Welcome to LVR.AI,{" "}
            <strong>{user.name ? user.name : "Anonymous User"}</strong>
          </p>
          <Divider />
          <h2>Marketing Units</h2>
          <Button appearance="primary" as={NavLink} to="unit/create">
            Add Marketing Unit
          </Button>
          <Table autoHeight data={user.marketing_units}>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.Cell style={{ padding: "6px" }}>
                {(rowData) => (
                  <Stack spacing={5} alignItems="center">
                    <Avatar
                      size="sm"
                      circle
                      src={
                        rowData.linked_in_author
                          ? getAuthorPicture(rowData.linked_in_author)
                          : ""
                      }
                    >
                      {String(rowData.name).charAt(0)}
                    </Avatar>
                    <span>{rowData.name}</span>
                  </Stack>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.Cell dataKey="type" />
            </Table.Column>
            <Table.Column fixed="right">
              <Table.HeaderCell>Operations</Table.HeaderCell>
              <Table.Cell style={{ padding: "6px" }}>
                {(rowData) => (
                  <Button
                    as={NavLink}
                    appearance="link"
                    to={`/unit/${rowData.id}`}
                  >
                    Open
                  </Button>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        </Panel>
      </FlexboxGridItem>
    </FlexboxGrid>
  );
};

export default HomePage;

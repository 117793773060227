import React from "react";
import { useEffect } from "react";
import { MutationTuple } from "@apollo/client";
import { useToaster, Message } from "rsuite";
import { ToastContainerProps } from "rsuite/esm/toaster/ToastContainer";

type UseMutationResponse<TData, TVariables> = MutationTuple<TData, TVariables>;
const toastConfig: ToastContainerProps = {
  placement: "bottomEnd",
  duration: 3000,
};



export const useNotificationWithMutation = <TData = any, TVariables = any>(
  mutationResponse: UseMutationResponse<TData, TVariables>,
  options: {
    loadingMessage?: string;
    successMessage?: string;
    errorMessage?: string;
  } = {
    loadingMessage: "Processing...",
    successMessage: "Operation successful",
    errorMessage: "Error: ",
  }
) => {
  const [_, { loading, error, data }] = mutationResponse;
  const toaster = useToaster();
  const toastMessage = (type, message) => {
    toaster.push(
      <Message closable showIcon type={type}>
        {message}
      </Message>,
      toastConfig
    );
  };
 

  useEffect(() => {
    if (error) {
      toastMessage("error", (options.errorMessage || `Error:`) + error.message);
    } else if (data) {
      toastMessage("success", options.successMessage || "Operation successful");
    }
  }, [loading, error, data]);

  return mutationResponse;
};

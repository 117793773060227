import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { GetLinkedInPostAssetsQuery } from "../graphql/__generated__/graphql";
import _ from "lodash";
import { ApolloQueryResult } from "@apollo/client";
import { LinkedInPostContentById, LinkedInPostContentType } from "../types";
import { Uploader } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import { useAuthenticatedContext } from "./AuthenticatedContextProvider";

const postContentTypeToMimeType = {
  [LinkedInPostContentType.Images]: "image/*",
  [LinkedInPostContentType.Videos]: "video/*",
};

const LinkedInPostAssetsInput: React.FC<{
  linkedInPostContent: LinkedInPostContentById;
  refetchAssets: (
    variables?:
      | Partial<{
          id: number;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<GetLinkedInPostAssetsQuery>>;
}> = ({ linkedInPostContent, refetchAssets }) => {
  const [fileList, setFileList] = React.useState<FileType[]>([]);
  const { token } = useAuthenticatedContext();
  return (
    <Uploader
      onSuccess={refetchAssets}
      multiple
      draggable
      accept={postContentTypeToMimeType[linkedInPostContent.contentType]}
      autoUpload={true}
      name="files"
      headers={{
        Authorization: `Bearer ${token}`,
      }}
      listType="picture-text"
      fileList={fileList}
      onChange={(fileList: FileType[]) => {
        setFileList(fileList);
      }}
      action={`${process.env.API_URL}/upload-media/${linkedInPostContent.id}`}
    >
      <div
        style={{
          height: 200,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>Click or Drag files to this area to upload (upload will start automatically).</span>
      </div>
    </Uploader>
  );
};

export default LinkedInPostAssetsInput;

type ArrayToString<T> = T extends Array<any> ? string : T;
type ToObjectWithFlatStrings<T> = {
  [P in keyof T]: ArrayToString<T[P]>;
};

const toArrayLiteral = (arr) =>
  JSON.stringify(arr).replace("[", "{").replace("]", "}");

export function toObjectWithArraysAsLiterals<T>(
  obj: T
): ToObjectWithFlatStrings<T> {
  const newObj = {} as ToObjectWithFlatStrings<T>;

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      newObj[key] = toArrayLiteral(obj[key]) as ArrayToString<T[typeof key]>;
    } else {
      newObj[key] = obj[key] as ArrayToString<T[typeof key]>;
    }
  }

  return newObj;
}

export const getAuthorPicture = (author: any) => {
  switch (author.profileType) {
    case "person":
      const maybeProfilePicture = author.externalRaw.profilePicture;
      if (maybeProfilePicture) {
        return maybeProfilePicture["displayImage~"].elements[0].identifiers[0]
          .identifier;
      }
      break;
    case "organization":
      const maybeLogo = author.externalRaw.logoV2;
      if (maybeLogo) {
        return maybeLogo["cropped~"].elements[0].identifiers[0].identifier;
      }
      break;
  }
  return null;
};

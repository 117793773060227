import React, { createContext, useContext, useEffect } from "react";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery } from "@apollo/client";
import {
  Campaign,
  DeleteLinkedInPostDocument,
  GetLinkedInPostAssetsDocument,
  GetLinkedInPostByIdDocument,
  InsertLinkedInPostDocument,
  Linked_In_Post_Content_Insert_Input,
  UpdateLinkedInPostDocument,
} from "../graphql/__generated__/graphql";
import { useNotificationWithMutation } from "../hooks/useNotificationWithMutation";
import { useMarketingUnit } from "./MarketingUnitContext";
import { Loading } from "./Loading";
import { useNavigate, useParams } from "react-router-dom";
import { DisplayError } from "./DisplayError";
import axios from "axios";
import AutoResizingTextarea from "./AutoResizingTextArea";
import _ from "lodash";
import { PropertyDiff } from "./PropertyDiff";
import {
  ContentStatus,
  LinkedInPostContentById,
  LinkedInPostContentType,
  PublicationState,
} from "../types";
import LinkedInPostPublication from "./LinkedInPostPublication";
import { useAuthenticatedContext } from "./AuthenticatedContextProvider";
import {
  Button,
  ButtonToolbar,
  Drawer,
  Form,
  InputPicker,
  Message,
  Panel,
  SelectPicker,
  Stack,
  useToaster,
} from "rsuite";
import Textarea from "./TextArea";
import { FaMagic, FaPoll } from "react-icons/fa";
import Icon from "@rsuite/icons/lib/Icon";
import { GoFileMedia } from "react-icons/go";
import MediaAssetPicker from "./MediaAssetPicker";
import AssetRenderer from "./AssetRenderer";
import useAxios from "axios-hooks";
import InlineCampaign from "./InlineCampaign";
import LinkedInPollForm from "./LinkedInPollForm";

interface ILinkedInPostContext {
  onUploadAssets?: (assets: any[], source: string) => Promise<void>;
  onPollUpsert?: (pollId: number) => Promise<void>;
}
const LinkedInPostContext = createContext<ILinkedInPostContext>({
  onUploadAssets: () => Promise.resolve(),
  onPollUpsert: () => Promise.resolve(),
});

export const useLinkedInPost = (): ILinkedInPostContext => {
  const context = useContext(LinkedInPostContext);
  if (!context) {
    throw new Error(
      "useLinkedInPost must be used within a LinkedInPostProvider"
    );
  }
  return context;
};

const LinkedInPostContent: React.FC = () => {
  const { marketingUnit, postsQuery } = useMarketingUnit();
  const [selectedCampaignId, setSelectedCampaignId] = useState<Campaign | null>(
    null
  );
  const campaignsSelectData = marketingUnit.campaigns.map((campaign) => {
    return {
      label: campaign.name,
      value: campaign.id,
      campaign,
    };
  });
  const toaster = useToaster();
  const [{}, savePexels] = useAxios(
    {
      baseURL: `${process.env.API_URL}/content-item/`,
      withCredentials: true,
      method: "post",
    },
    {
      manual: true,
    }
  );
  const { fetcher } = useAuthenticatedContext();
  const navigate = useNavigate();
  const { postId } = useParams();
  const [openMediaAssetDrawer, setOpenMediaAssetDrawer] = useState(false);
  const [openPollDrawer, setOpenPollDrawer] = useState(false);
  const initialLinkedInPostContent: LinkedInPostContentById = {
    contentStatus: ContentStatus.DRAFT,
    contentType: LinkedInPostContentType.Text,
    id: -1,
    name: "",
    commentary: "",
    description: "",
    instructions: "",
    campaign: undefined,
  };
  const initialLinkedInPostContentInput: Linked_In_Post_Content_Insert_Input = {
    contentStatus: ContentStatus.DRAFT,
    contentType: LinkedInPostContentType.Text,
    id: -1,
    name: "",
    commentary: "",
    description: "",
    instructions: "",
  };

  const [isGeneratingCommentary, setIsGeneratingCommentary] = useState(false);
  const [linkedInPostContent, setLinkedInPostContent] =
    useState<LinkedInPostContentById>(initialLinkedInPostContent);
  const [linkedInPostContentInsertInput, setLinkedInPostContentInsertInput] =
    useState<Linked_In_Post_Content_Insert_Input>(
      initialLinkedInPostContentInput
    );

  const {
    data: assetsData,
    loading: loadingAssets,
    error: errorAssets,
    refetch: refetchAssets,
  } = useQuery(GetLinkedInPostAssetsDocument, {
    // @ts-ignore
    variables: { id: linkedInPostContent?.id },
    skip: !linkedInPostContent || linkedInPostContent.id === -1,
  });

  const {
    data: linkedInContentData,
    loading,
    error,
    refetch,
  } = useQuery(GetLinkedInPostByIdDocument, {
    // @ts-ignore
    variables: { id: postId },
    skip: postId === undefined,
  });

  const [insertLinkedInPost] = useNotificationWithMutation(
    useMutation(InsertLinkedInPostDocument),
    {
      loadingMessage: "Saving linked in post",
      successMessage: "Saved linked in post",
      errorMessage: "Failed to save linked in post",
    }
  );
  const [updadeLinkedInPost] = useNotificationWithMutation(
    useMutation(UpdateLinkedInPostDocument),
    {
      loadingMessage: "Saving linked in post",
      successMessage: "Saved linked in post",
      errorMessage: "Failed to save linked in post",
    }
  );
  const [deleteLinkedInPost] = useNotificationWithMutation(
    useMutation(DeleteLinkedInPostDocument),
    {
      loadingMessage: "Deleting linked in post",
      successMessage: "Deleted linked in post",
      errorMessage: "Failed to delete linked in post",
    }
  );

  const onUploadAssets = async (assets: any[], source: string) => {
    if (assets.length === 0) return;
    if (source === "pexels") {
      try {
        await savePexels({
          url: `${postId}/assets/pexels`,
          data: {
            images: assets,
          },
        });
        toaster.push(<Message type="success">Saved Pexels assets</Message>);
        await refetchAssets();
      } catch (errors: any) {
        toaster.push(
          <Message type="error">Failed to save Pexels assets</Message>
        );
        return Promise.reject(errors);
      }
    }
  };

  const onPollUpsert = async (pollId: number) => {
    if (pollId === undefined || postId === undefined) return;
    await updadeLinkedInPost({
      variables: {
        id: Number(postId),
        _set: {
          pollId,
        },
      },
    });
    await refetch();
  };

  useEffect(() => {
    if (
      postId !== undefined &&
      linkedInContentData &&
      linkedInContentData.linked_in_post_content_by_pk
    ) {
      const { campaign } = linkedInContentData.linked_in_post_content_by_pk;
      if (campaign) setSelectedCampaignId(campaign.id);
      setLinkedInPostContent(
        _.omit(linkedInContentData.linked_in_post_content_by_pk, "__typename")
      );
      setLinkedInPostContentInsertInput({
        ..._.omit(
          linkedInContentData.linked_in_post_content_by_pk,
          "__typename",
          "campaign",
          "poll"
        ),
      });
    } else if ([postId === undefined]) {
      setSelectedCampaignId(null);
      setLinkedInPostContent(initialLinkedInPostContent);
      setLinkedInPostContentInsertInput(initialLinkedInPostContentInput);
    }
  }, [linkedInContentData, postId]);

  if (postId !== undefined) {
    if (loading || !linkedInContentData) return <Loading />;
    if (error) return <DisplayError error={error} />;
  }

  const handleInputChange = (value: any, name: string) => {
    setLinkedInPostContentInsertInput((prevContent) => ({
      ...prevContent,
      [name]: value,
    }));
  };

  const handleRemoveContent = async (event) => {
    event.preventDefault();
    if (postId !== undefined) {
      await deleteLinkedInPost({
        variables: {
          id: Number(postId),
        },
      });
      navigate("./../..");
      postsQuery.refetch();
    }
  };

  const handleSaveClick = async (event) => {
    event.preventDefault();
    if (postId === undefined) {
      const { data: newLinkedInPost, errors } = await insertLinkedInPost({
        variables: {
          object: {
            marketingUnitId: marketingUnit.id,
            ..._.omit(linkedInPostContentInsertInput, "id"),
            campaignId: selectedCampaignId,
            publication: {
              data: {
                publicationDate: new Date(),
                state: PublicationState.DRAFT,
              },
            },
          },
        },
      });
      if (
        !errors &&
        newLinkedInPost &&
        newLinkedInPost.insert_linked_in_post_content_one
      ) {
        navigate(`./${newLinkedInPost.insert_linked_in_post_content_one.id}`);
        postsQuery.refetch();
      }
    } else {
      await updadeLinkedInPost({
        variables: {
          id: Number(postId),
          _set: {
            marketingUnitId: marketingUnit.id,
            campaignId: selectedCampaignId,
            ...linkedInPostContentInsertInput,
          },
        },
      });
      refetch();
    }
  };

  const generateLinkedInPostCommentary = async (event) => {
    event.preventDefault();
    setIsGeneratingCommentary(true);

    const response = await axios.post(
      `${process.env.API_URL}/create-linkedin-post-commentary-job`,
      {
        linkedInPostContent: linkedInPostContentInsertInput,
        campaignId: selectedCampaignId,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          marketingUnitId: marketingUnit.id,
        },
      }
    );

    const { jobId } = response.data;
    if (response.status === 200) {
      const streamResponse = await fetcher(
        `${process.env.API_URL}/stream-linkedin-post-commentary/${jobId}`
      );

      // Get the reader from the response body
      if (streamResponse === null || streamResponse.body == null) {
        setIsGeneratingCommentary(false);
        return;
      }
      const reader = streamResponse.body.getReader();
      let decoder = new TextDecoder();

      const chunks: string[] = [];
      // Start reading from the stream and process each chunk of data
      reader
        .read()
        .then(function processText({ done, value }) {
          if (done) {
            console.log("Stream complete");
            setIsGeneratingCommentary(false);
            return;
          }

          // Convert the Uint8Array into a string and add it to your commentary stream data
          console.log("value", value);
          const chunk = decoder.decode(value);
          console.log("chunk", chunk);
          chunks.push(chunk);
          setLinkedInPostContentInsertInput({
            ...linkedInPostContentInsertInput,
            commentary: chunks.join(""),
          });

          // Keep reading from the stream
          return reader.read().then(processText);
        })
        .catch((error) => {
          console.error("Stream reading failed:", error);
          setIsGeneratingCommentary(false);
        });
    } else {
      setIsGeneratingCommentary(false);
    }
  };

  const assetsList = (
    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {loadingAssets ? "Loading media..." : null}
      {errorAssets ? (
        <Message type="error">{errorAssets.message}</Message>
      ) : null}
      {(assetsData ? assetsData.asset : []).map((asset) => (
        <AssetRenderer
          key={`asset-${asset.id}`}
          asset={asset}
          onRemove={() => {
            refetchAssets();
          }}
        />
      ))}
    </div>
  );

  return (
    <Stack justifyContent="space-between" alignItems="flex-start" spacing={20}>
      <Stack.Item flex={1}>
        <Drawer
          open={openMediaAssetDrawer}
          onClose={() => setOpenMediaAssetDrawer(false)}
          size="md"
        >
          <Drawer.Body>
            <LinkedInPostContext.Provider value={{ onUploadAssets }}>
              <MediaAssetPicker
                linkedInPostContent={linkedInPostContent}
                refetchAssets={refetchAssets}
              />
            </LinkedInPostContext.Provider>
          </Drawer.Body>
        </Drawer>
        <Drawer
          open={openPollDrawer}
          onClose={() => setOpenPollDrawer(false)}
          size="md"
        >
          <Drawer.Body>
            <LinkedInPostContext.Provider value={{ onPollUpsert }}>
              <LinkedInPollForm content={linkedInPostContent} />
            </LinkedInPostContext.Provider>
          </Drawer.Body>
        </Drawer>
        <Panel>
          <h2>Content Editor</h2>
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control
                name="name"
                value={linkedInPostContentInsertInput.name || ""}
                onChange={(value) => handleInputChange(value, "name")}
              />
              <PropertyDiff
                entity={linkedInPostContent}
                input={linkedInPostContentInsertInput}
                propName="name"
                propDisplayName="Name"
              />
              <Form.HelpText>
                The name is just for you as the editor and AI to differentiate
                between content items and inform what the content is about
              </Form.HelpText>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Form.Control
                accepter={Textarea}
                name="description"
                value={linkedInPostContentInsertInput.description || ""}
                onChange={(value) => handleInputChange(value, "description")}
              />
              <PropertyDiff
                entity={linkedInPostContent}
                input={linkedInPostContentInsertInput}
                propName="description"
                propDisplayName="Description"
              />
              <Form.HelpText>
                Description of this content item for editors. AI will use this
                as input for what a detailed description of what the content
                item is about.
              </Form.HelpText>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Campaign</Form.ControlLabel>
              <Form.Control
                accepter={SelectPicker}
                data={campaignsSelectData}
                name="campaign"
                value={selectedCampaignId}
                onChange={(value) => setSelectedCampaignId(value)}
                renderMenuItem={(_, item) => (
                  <InlineCampaign campaignProps={item.campaign} />
                )}
                renderValue={(_, item) =>
                  !!item ? (
                    // @ts-ignore
                    <InlineCampaign campaignProps={item.campaign} />
                  ) : (
                    ""
                  )
                }
              />
              <Form.HelpText>
                Optionally, select campaign this content item belongs to.
              </Form.HelpText>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>
                Additional instructions for AI
              </Form.ControlLabel>
              <Form.Control
                accepter={Textarea}
                name="instructions"
                value={linkedInPostContentInsertInput.instructions || ""}
                onChange={(value) => handleInputChange(value, "instructions")}
              />
              <PropertyDiff
                entity={linkedInPostContent}
                input={linkedInPostContentInsertInput}
                propName="instructions"
                propDisplayName="Instructions"
              />
              <Form.HelpText>
                Tell AI anything else about the content item that can help
                produce quality content
              </Form.HelpText>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Commentary (content body)</Form.ControlLabel>
              <AutoResizingTextarea
                name="commentary"
                value={linkedInPostContentInsertInput.commentary || ""}
                onChange={(event) =>
                  handleInputChange(event.target.value, "commentary")
                }
                rows={5}
              />
              <PropertyDiff
                entity={linkedInPostContent}
                input={linkedInPostContentInsertInput}
                propName="commentary"
                propDisplayName="Commentary"
              />
              <Form.HelpText>
                This is the actual content that you will publish.
              </Form.HelpText>
              <Button
                startIcon={<FaMagic />}
                onClick={generateLinkedInPostCommentary}
                appearance="primary"
                loading={isGeneratingCommentary}
                disabled={isGeneratingCommentary}
              >
                Generate
              </Button>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Content type</Form.ControlLabel>
              <InputPicker
                cleanable={false}
                name="contentType"
                value={
                  linkedInPostContentInsertInput.contentType ||
                  LinkedInPostContentType.Text
                }
                onChange={(value) => handleInputChange(value, "contentType")}
                data={Object.values(LinkedInPostContentType).map((type) => ({
                  label: type,
                  value: type,
                }))}
              />

              <PropertyDiff
                entity={linkedInPostContent}
                input={linkedInPostContentInsertInput}
                propName="contentType"
                propDisplayName="Content Type"
              />
              <Form.HelpText>Type of published content</Form.HelpText>
            </Form.Group>
            {[LinkedInPostContentType.Images].includes(
              linkedInPostContentInsertInput.contentType
            ) &&
              linkedInPostContent.id !== -1 && (
                <Form.Group>
                  <Form.ControlLabel>Media</Form.ControlLabel>
                  <Button
                    appearance="primary"
                    startIcon={<Icon as={GoFileMedia} />}
                    onClick={() => setOpenMediaAssetDrawer(true)}
                  >
                    Open Media Asset Picker
                  </Button>
                  {assetsList}
                </Form.Group>
              )}
            {[LinkedInPostContentType.Poll].includes(
              linkedInPostContentInsertInput.contentType
            ) &&
              linkedInPostContent.id !== -1 && (
                <Form.Group>
                  <Form.ControlLabel>Poll</Form.ControlLabel>
                  <Button
                    appearance="primary"
                    startIcon={<Icon as={FaPoll} />}
                    onClick={() => setOpenPollDrawer(true)}
                  >
                    Open Poll Editor
                  </Button>
                  {assetsList}
                </Form.Group>
              )}

            <ButtonToolbar>
              <Button
                disabled={_.isEqual(
                  linkedInPostContentInsertInput,
                  linkedInPostContent
                )}
                onClick={handleSaveClick}
                appearance="primary"
              >
                Save Content
              </Button>
              {postId !== undefined && (
                <Button
                  onClick={handleRemoveContent}
                  color="red"
                  appearance="primary"
                >
                  Remove Content
                </Button>
              )}
            </ButtonToolbar>
          </Form>
        </Panel>
      </Stack.Item>
      <Stack.Item style={{ width: "400px" }}>
        <Panel>
          <LinkedInPostPublication
            publicationId={linkedInPostContent.publicationId}
            contentId={linkedInPostContent.id}
          />
        </Panel>
      </Stack.Item>
    </Stack>
  );
};

export default LinkedInPostContent;

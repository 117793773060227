import React from "react";
import { NavLink } from "react-router-dom";

const CustomNavLink = ({ to, children, className, ...rest }) => (
    <NavLink
      to={to}
      end
      className={({ isActive }) =>
        isActive ? "rs-nav-item-active " + className : className
      }
      {...rest}
    >
      {children}
    </NavLink>
  );

export default CustomNavLink
import SearchIcon from "@rsuite/icons/Search";
import Icon from "@rsuite/icons/lib/Icon";
import React, { createContext, useContext } from "react";
import { BiGlassesAlt } from "react-icons/bi";
import { Badge, Nav, Panel } from "rsuite";
import Search from "./Search";
import { BraveSearchResultItem } from "../../types";
import Analyze from "./Analyze";

interface IResearchContext {
  onChangeSelectSearchResult: (
    item: BraveSearchResultItem,
    shouldSelect: boolean
  ) => void;
  selectedSearchResults: BraveSearchResultItem[];
}

const ResearchContext = createContext<IResearchContext>({
  onChangeSelectSearchResult: () => {},
  selectedSearchResults: [],
});

export const useResearchContext = (): IResearchContext => {
  const context = useContext(ResearchContext);
  if (!context) {
    throw new Error(
      "useResearchContext must be used within a ResearchContextProvider"
    );
  }
  return context;
};

const Research: React.FC = () => {
  const [selectedTab, setSeletedTab] = React.useState("analyze");
  const [selectedSearchResults, setSelectedSearchResults] = React.useState<
    BraveSearchResultItem[]
  >([]);

  const onChangeSelectSearchResult = (
    item: BraveSearchResultItem,
    shouldSelect: boolean
  ) => {
    if (shouldSelect) {
      setSelectedSearchResults([...selectedSearchResults, item]);
    } else {
      setSelectedSearchResults(
        selectedSearchResults.filter((result) => result.url !== item.url)
      );
    }
  };

  return (
    <Panel>
      <h1>Research</h1>
      <Nav
        className="mb-3"
        activeKey={selectedTab}
        onSelect={setSeletedTab}
        appearance="tabs"
      >
        <Nav.Item eventKey="analyze" icon={<Icon as={BiGlassesAlt}></Icon>}>
          <Badge
            content={
              selectedSearchResults.length > 0
                ? selectedSearchResults.length
                : false
            }
          >
            <span>Analyze</span>
          </Badge>
        </Nav.Item>
        <Nav.Item eventKey="search" icon={<SearchIcon />}>
          Search
        </Nav.Item>
      </Nav>
      <ResearchContext.Provider
        value={{ onChangeSelectSearchResult, selectedSearchResults }}
      >
        <div className={selectedTab !== "search" ? "hidden" : ""}>
          <Search></Search>
        </div>
        <div className={selectedTab !== "analyze" ? "hidden" : ""}>
          <Analyze searchResults={selectedSearchResults}></Analyze>
        </div>
      </ResearchContext.Provider>
    </Panel>
  );
};

export default Research;

import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { MarketingUnitProvider } from "./MarketingUnitContext";
import BrandGuidelineComponent from "./BrandGuideline";
import LinkedInPostList from "./LinkedInPostList";
import Integrations from "./Integrations";
import { useUser } from "./InitialiseApp";
import LinkedInPostContent from "./LinkedInPostContent";
import { Container, Content } from "rsuite";
import Header from "./Header";
import HomePage from "./HomePage";
import { MarketingUnitForm } from "./MarketingUnitForm";
import Research from "./Research";
import Campaigns from "./Campaigns";
import CreateCampaign from "./CreateCampaign";

const LoggedInUserRoutes: React.FC = () => {
  const { user } = useUser();
  return (
    <>
      <Header user={user} />
      <Routes>
        <Route
          path="/"
          element={
            <Container style={{ padding: "12px" }}>
              <Content>
                <Outlet />
              </Content>
            </Container>
          }
        >
          <Route path="" element={<HomePage user={user} />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="research" element={<Research />}></Route>
          <Route path="unit">
            <Route path="create" element={<MarketingUnitForm />} />
            <Route path=":id/*" element={<MarketingUnitProvider user={user} />}>
              <Route index element={<Navigate to="content" replace />} />
              <Route
                path="brand-and-strategy"
                element={<BrandGuidelineComponent />}
              />

              <Route path="campaigns" element={<Campaigns />} />
              <Route path="campaigns/editor" element={<CreateCampaign />} />
              <Route
                path="campaigns/editor/:campaignId"
                element={<CreateCampaign />}
              />
              <Route path="content" element={<LinkedInPostList />} />
              <Route path="content/editor" element={<LinkedInPostContent />} />
              <Route
                path="content/editor/:postId"
                element={<LinkedInPostContent />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default LoggedInUserRoutes;

import Icon from "@rsuite/icons/lib/Icon";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Avatar, Button, ButtonToolbar, Panel, Stack, Table } from "rsuite";
import { useMarketingUnit } from "../MarketingUnitContext";
import { Emoji } from "emoji-picker-react";

const Campaigns: React.FC = () => {
  const { marketingUnitQuery } = useMarketingUnit();
  return (
    <Panel>
      <h1>Campaigns</h1>
      <ButtonToolbar className="mt-1 mb-1">
        <Button
          as={NavLink}
          to="../campaigns/editor"
          startIcon={<Icon as={FaPlus} />}
          appearance="primary"
        >
          Create Campaign
        </Button>
      </ButtonToolbar>
      <Table
        autoHeight
        loading={marketingUnitQuery.loading}
        data={marketingUnitQuery.data? marketingUnitQuery.data.marketing_unit_by_pk?.campaigns : []}
      >
        <Table.Column width={200}>
          <Table.HeaderCell>Campaign</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              const campaign = rowData;
              if (!campaign) return "";
              const { color, emoji, name } = campaign;
              return (
                <Stack spacing={3} alignItems="center">
                  <Avatar size="sm" style={{ background: color }}>
                    {emoji && <Emoji unified={emoji} size={18} />}
                  </Avatar>
                  <span>{name}</span>
                </Stack>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column fullText flexGrow={2}>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.Cell dataKey="description"></Table.Cell>
        </Table.Column>
        <Table.Column width={100} fixed="right">
          <Table.HeaderCell>Operations</Table.HeaderCell>
          <Table.Cell style={{ padding: "6px" }}>
            {(rowData) => (
              <Button
                as={NavLink}
                appearance="primary"
                to={`editor/${rowData.id}`}
              >
                Edit
              </Button>
            )}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Panel>
  );
};

export default Campaigns;

import { Emoji } from "emoji-picker-react";
import React from "react";
import { Avatar, Stack } from "rsuite";

const InlineCampaign: React.FC<{
  campaignProps: { emoji?: string | null, name?: string | null; color?: string | null };
}> = ({ campaignProps }) => {
  const { name, emoji, color } = campaignProps;
  return (
    (name || emoji || color) && (
      <Stack spacing={3} alignItems="center">
        <Avatar size="sm" style={{ background: color || "" }}>
          {emoji && <Emoji unified={emoji} size={18} />}
        </Avatar>
        <span>{name}</span>
      </Stack>
    )
  );
};

export default InlineCampaign;

// QuestionComponent.tsx
import React, { useState, useEffect } from "react";
import { InterviewQuestion } from "../types";
import CreatableSelect from "react-select/creatable"; // Add this import
import { Form, Input } from "rsuite";
import Textarea from "./TextArea";

interface QuestionComponentProps {
  question: InterviewQuestion;
  value: string | string[];
  onSelect: (option: string | string[]) => void;
}

const QuestionComponent: React.FC<QuestionComponentProps> = ({
  question,
  value,
  onSelect,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | string[]>(
    value
  );

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const handleOptionChange = (
    value
  ) => {
    
    setSelectedOption(value);
    onSelect(value);
  };

  const renderInputByType = () => {
    switch (question.type) {
      case "checkbox":
      case "radio":
        return question.options?.map((option, index) => (
          <div key={index}>
            <input
              type={question.type}
              className="rounded px-2 py-1 w-full"
              id={`option-${index}`}
              name="options"
              value={option}
              checked={selectedOption === option}
              onChange={handleOptionChange}
            />
            <label htmlFor={`option-${index}`}>{option}</label>
          </div>
        ));
      case "textarea":
        return (
          <Form.Control
            accepter={Textarea}
            name={question.fieldName}
            value={selectedOption}
            onChange={handleOptionChange}
          />
        );
      case "multiselect":
        const options = question.options?.map((option) => ({
          value: option,
          label: option,
        }));

        const handleReactSelectChange = (selectedOptions: any) => {
          if (Array.isArray(selectedOptions)) {
            const selectedValues = selectedOptions.map(
              (option: any) => option.value
            );
            setSelectedOption(selectedValues);
            onSelect(selectedValues);
          } else {
            setSelectedOption([]);
            onSelect([]);
          }
        };

        return (
          <CreatableSelect // Use CreatableSelect instead of ReactSelect
            isMulti
            options={options}
            value={
              selectedOption // Check if selectedOption exists
                ? (selectedOption as string[]).map((value) => ({
                    value,
                    label: value,
                  }))
                : []
            }
            placeholder="Select options or type in your replies"
            onChange={handleReactSelectChange}
            className="w-full select"
            classNamePrefix="aura"
            allowCreateWhileLoading={true} // Add this prop
            createOptionPosition="first" // Add this prop to specify the position of the created option
          />
        );
      case "text":
      default:
        return (
          <input
            type="text"
            className="rounded px-2 py-1 w-full"
            value={selectedOption}
            onChange={handleOptionChange}
          />
        );
    }
  };

  return (
    <Form.Group controlId={question.fieldName} key={question.fieldName}>
      <Form.ControlLabel>{question.fieldName}</Form.ControlLabel>
      <Form.HelpText>{question.question}</Form.HelpText>
      {renderInputByType()}
    </Form.Group>
  );
};

export default QuestionComponent;

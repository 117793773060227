import { useMutation, useQuery } from "@apollo/client";
import useAxios from "axios-hooks";
import { format } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { MdScheduleSend, MdSend } from "react-icons/md";
import { toast } from "react-toastify";
import { Button, DatePicker, Form, InputGroup, Message, Stack } from "rsuite";
import {
  GetLinkedInPostPublicationByIdDocument,
  GetLinkedInPostPublicationByIdQuery,
  UpdateLinkedInPostPublicationDocument,
} from "../graphql/__generated__/graphql";
import { PublicationState } from "../types";
import { DisplayError } from "./DisplayError";
import { useMarketingUnit } from "./MarketingUnitContext";

const LinkedInPostPublication: React.FC<{
  publicationId?: number | null;
  contentId: number;
}> = ({ publicationId, contentId }) => {
  const { postsQuery } = useMarketingUnit();
  const initialPublication: NonNullable<
    GetLinkedInPostPublicationByIdQuery["linked_in_post_publication_by_pk"]
  > = {
    state: PublicationState.DRAFT,
    publicationDate: new Date(),
    urn: undefined,
  };
  const [publication, setPublication] =
    useState<
      NonNullable<
        GetLinkedInPostPublicationByIdQuery["linked_in_post_publication_by_pk"]
      >
    >(initialPublication);

  const {
    data: dataLinkedInPublication,
    loading: loadingLinkedInPublication,
    error: errorLinkedInPublication,
    refetch,
  } = useQuery(GetLinkedInPostPublicationByIdDocument, {
    // @ts-ignore
    variables: { id: publicationId },
    skip: !publicationId,
  });

  const [updatePublicationSettings] = useMutation(
    UpdateLinkedInPostPublicationDocument
  );

  const [{ loading: loadingDeletePublication }, deletePublication] = useAxios(
    {
      method: "delete",
      baseURL: `${process.env.API_URL}/delete-linkedin-publication`,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    },
    {
      manual: true,
    }
  );

  const [{ loading: loadingPostPublish }, postPublish] = useAxios(
    {
      method: "post",
      baseURL: `${process.env.API_URL}/publish-linkedin`,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    },
    {
      manual: true,
    }
  );

  const [{ loading: loadingPostSchedule }, postSchedule] = useAxios(
    {
      method: "post",
      baseURL: `${process.env.API_URL}/schedule-linkedin`,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    },
    {
      manual: true,
    }
  );

  const [{ loading: loadingPostUnschedule }, postUnschedule] = useAxios(
    {
      method: "post",
      baseURL: `${process.env.API_URL}/unschedule-linkedin`,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (publicationId !== undefined) {
      refetch();
    }
  }, [publicationId]);

  useEffect(() => {
    if (
      !!dataLinkedInPublication &&
      !!dataLinkedInPublication.linked_in_post_publication_by_pk &&
      dataLinkedInPublication.linked_in_post_publication_by_pk.state ===
        PublicationState.UPLOADING_ASSETS
    ) {
      const interval = setInterval(() => {
        console.log(
          "Refetching LinkedIn publication to see if it is published."
        );
        refetch();
      }, 3000);
      return () => clearInterval(interval);
    }
    return;
  }, [dataLinkedInPublication]);

  useEffect(() => {
    if (
      !!dataLinkedInPublication &&
      !!dataLinkedInPublication.linked_in_post_publication_by_pk
    ) {
      const savedPublication = _.omit(
        dataLinkedInPublication.linked_in_post_publication_by_pk,
        "__typename"
      );
      setPublication(savedPublication);
    }
  }, [dataLinkedInPublication]);

  if (loadingLinkedInPublication) {
    return <p>Loading...</p>;
  }
  if (errorLinkedInPublication) {
    return <DisplayError error={errorLinkedInPublication} />;
  }

  const handleScheduleDateChange = async (date: Date | null) => {
    if (!date || !publicationId) return;
    let datetimeZ = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
    await updatePublicationSettings({
      variables: {
        id: publicationId,
        _set: {
          publicationDate: datetimeZ,
        },
      },
    });
    refetch();
  };

  const publish = async (event) => {
    event.preventDefault();
    try {
      const response = await postPublish({
        data: {
          linkedInPostContentId: contentId,
        },
      });
      if (response.status === 200) {
        await refetch();
        await postsQuery.refetch();
      }
    } catch (error) {
      toast.error("Failed to publish on LinkedIn");
      console.error("Error while publishing content:", error);
    }
  };

  const schedule = async () => {
    try {
      const response = await postSchedule({
        data: {
          linkedInPostContentId: contentId,
        },
      });
      if (response.status === 200) {
        await refetch();
        await postsQuery.refetch();
      }
    } catch (error) {
      toast.error("Failed to schedule on LinkedIn");
      console.error("Error while scheduling content:", error);
    }
  };

  const unschedule = async (event) => {
    event.preventDefault();
    try {
      const response = await postUnschedule({
        data: {
          linkedInPostContentId: contentId,
        },
      });
      if (response.status === 200) {
        refetch();
      }
    } catch (error) {
      toast.error("Failed to unschedule on LinkedIn");
      console.error("Error while unscheduling content:", error);
    }
  };

  if (contentId === -1) {
    return (
      <Message type="info" header="Publishing">
        Please save your content first to enable publishing options.
      </Message>
    );
  }

  return (
    <Form>
      <h2>Publication</h2>
      {/* <Form.Group>
        <Form.ControlLabel>Publication State</Form.ControlLabel>
        <Form.Control plaintext name="state" value={publication.state} />
        <Form.HelpText>
          Current state of the publication (read only)
        </Form.HelpText>
      </Form.Group> */}
      {publication.state === PublicationState.SCHEDULED && (
        <Form.Group>
          <Form.ControlLabel>Going live date</Form.ControlLabel>
          <Form.Control
            plaintext
            accepter={DatePicker}
            name="publicationDate"
            format="MMM dd, yyyy h:mm aa"
            value={
              publication.publicationDate
                ? new Date(publication.publicationDate)
                : undefined
            }
          ></Form.Control>
        </Form.Group>
      )}
      {publication.state === PublicationState.UPLOADING_ASSETS && (
        <Form.Group>
          <Message type="info" showIcon>
            Synchronizing images with LinkedIn takes longer than usual. We will
            keep checking the status but you may need to come back later if it
            takes more than few minutes.
          </Message>
        </Form.Group>
      )}
      {publication.state === PublicationState.PUBLISHED &&
        !!publication.urn && (
          <Form.Group>
            <Form.ControlLabel>You content is live!</Form.ControlLabel>
            <Form.HelpText>
              <a
                href={`https://www.linkedin.com/feed/update/${publication.urn}`}
                target="_new"
              >
                {publication.urn}
              </a>
            </Form.HelpText>
          </Form.Group>
        )}

      {![PublicationState.PUBLISHED, PublicationState.SCHEDULED].includes(
        publication.state
      ) && (
        <Stack direction="column" spacing={5} alignItems="stretch">
          <Button
            className="w-full"
            startIcon={<MdSend />}
            onClick={publish}
            disabled={loadingPostPublish || loadingPostSchedule}
            appearance="primary"
            loading={
              loadingPostPublish ||
              publication.state === PublicationState.UPLOADING_ASSETS
            }
          >
            Publish Now
          </Button>
          <InputGroup>
            <DatePicker
              className="flex-grow"
              format="MMM dd, yyyy h:mm aa"
              cleanable={false}
              value={
                publication.publicationDate
                  ? new Date(publication.publicationDate)
                  : undefined
              }
              calendarDefaultDate={new Date()}
              ranges={[
                {
                  label: "Now",
                  value: new Date(),
                },
              ]}
              name="publicationDate"
              onChange={handleScheduleDateChange}
            />
            <InputGroup.Button
              startIcon={<MdScheduleSend />}
              onClick={schedule}
              disabled={loadingPostPublish || loadingPostSchedule}
              appearance="primary"
              loading={
                loadingPostSchedule ||
                publication.state === PublicationState.UPLOADING_ASSETS
              }
            >
              Schedule
            </InputGroup.Button>
          </InputGroup>
        </Stack>
      )}

      {publication.state === PublicationState.PUBLISHED && (
        <Button
          color="red"
          className="w-full"
          appearance="primary"
          disabled={loadingDeletePublication}
          loading={loadingDeletePublication}
          onClick={async (event) => {
            event.preventDefault();
            await deletePublication({
              url: `/${contentId}`,
            });
            refetch();
          }}
        >
          Unpublish
        </Button>
      )}

      {publication.state === PublicationState.SCHEDULED && (
        <Button
          color="orange"
          className="w-full"
          appearance="primary"
          disabled={loadingPostUnschedule}
          loading={loadingPostUnschedule}
          onClick={unschedule}
        >
          Unschedule
        </Button>
      )}
    </Form>
  );
};

export default LinkedInPostPublication;

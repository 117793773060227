import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { FaMagic } from "react-icons/fa";
import {
  Button,
  ButtonToolbar,
  Form,
  Schema,
  SelectPicker,
  Table,
} from "rsuite";
import { LinkedInPostContentType, PublicationState } from "../../types";
import InlineCampaign from "../InlineCampaign";
import { useMarketingUnit } from "../MarketingUnitContext";
import Textarea from "../TextArea";
import { useMutation } from "@apollo/client";
import { InsertLinkedInPostDocument } from "../../graphql/__generated__/graphql";
import { useNotificationWithMutation } from "../../hooks/useNotificationWithMutation";

type SuggestedPlanItem = {
  name: string;
  description: string;
  contentType: LinkedInPostContentType;
  publicationDate: string;
};

const validationModel = Schema.Model({
  description: Schema.Types.StringType().isRequired("This field is required."),
});

const GenerateCampaignPlan: React.FC = () => {
  const { marketingUnit, postsQuery } = useMarketingUnit();
  const [campaignId, setCampaignId] = useState<string | null>(null);
  const [suggestedPlan, setSuggestedPlan] = useState<SuggestedPlanItem[]>([]);
  const [description, setDescription] = useState("");
  const campaignsSelectData = marketingUnit.campaigns.map((campaign) => {
    return {
      label: campaign.name,
      value: campaign.id,
      campaign,
    };
  });

  const [
    {
      loading: loadingGenerateCampaign,
      error: errorGenerateCampaign,
      data: generateCampaignResponse,
    },
    generateContentPlan,
  ] = useAxios(
    {
      method: "post",
      baseURL: `${process.env.API_URL}/`,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    },
    {
      manual: true,
    }
  );

  const [insertLinkedInPost] = useNotificationWithMutation(
    useMutation(InsertLinkedInPostDocument),
    {
      loadingMessage: "Saving linked in post",
      successMessage: "Saved linked in post",
      errorMessage: "Failed to save linked in post",
    }
  );

  const handleGenerateContentPlan = async (isValid) => {
    if (!isValid) {
      return;
    }
    setSuggestedPlan([]);
    await generateContentPlan({
      data: {
        description,
        campaignId,
      },
      url: `${process.env.API_URL}/generate-suggested-content-plan/${marketingUnit.id}`,
    });
  };

  useEffect(() => {
    if (
      !loadingGenerateCampaign &&
      !errorGenerateCampaign &&
      !!generateCampaignResponse
    ) {
      setSuggestedPlan(generateCampaignResponse.suggestedPlan);
    }
  }, [loadingGenerateCampaign, generateCampaignResponse]);

  return (
    <>
      <h2>Create a series</h2>
      <Form model={validationModel} fluid onSubmit={handleGenerateContentPlan}>
        <Form.Group>
          <Form.ControlLabel>Description</Form.ControlLabel>
          <Form.Control
            accepter={Textarea}
            value={description}
            onChange={setDescription}
            name="description"
          ></Form.Control>
          <Form.HelpText>
            Describe what do you want this series to be about. You can mention
            topics, scope, requirements, etc. This will be used to suggest a
            content plan but it won't be memorized for later use to generate
            content.
          </Form.HelpText>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Campaign</Form.ControlLabel>
          <Form.Control
            accepter={SelectPicker}
            data={campaignsSelectData}
            name="campaignId"
            value={campaignId}
            onChange={(value) => setCampaignId(value)}
            renderMenuItem={(_, item) => (
              <InlineCampaign campaignProps={item.campaign} />
            )}
            renderValue={(_, item) =>
              !!item ? (
                // @ts-ignore
                <InlineCampaign campaignProps={item.campaign} />
              ) : (
                ""
              )
            }
          />
          <Form.HelpText>
            Is this series part of a campaign? Select if you want the generated
            content to be consistent across a campaign.
          </Form.HelpText>
        </Form.Group>
        <Button
          loading={loadingGenerateCampaign}
          startIcon={<FaMagic />}
          appearance="primary"
          type="submit"
        >
          Generate
        </Button>
        {loadingGenerateCampaign && " This may take a minute or two..."}
      </Form>
      {suggestedPlan.length > 0 && (
        <Table
          autoHeight
          wordWrap
          loading={loadingGenerateCampaign}
          data={suggestedPlan}
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell dataKey="name"></Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={2}>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.Cell dataKey="description"></Table.Cell>
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.Cell dataKey="contentType"></Table.Cell>
          </Table.Column>
          <Table.Column width={180} fixed="right">
            <Table.HeaderCell>Operations</Table.HeaderCell>
            <Table.Cell style={{ padding: "6px" }}>
              {(rowData, rowIndex) => (
                <ButtonToolbar>
                  <Button
                    appearance="primary"
                    color="green"
                    onClick={async (event) => {
                      event.preventDefault();
                      const { data, errors } = await insertLinkedInPost({
                        variables: {
                          object: {
                            marketingUnitId: marketingUnit.id,
                            name: rowData.name,
                            description: rowData.description,
                            contentType: rowData.contentType,
                            campaignId,
                            publication: {
                              data: {
                                publicationDate: new Date(),
                                state: PublicationState.DRAFT,
                              },
                            },
                          },
                        },
                      });
                      if (
                        !errors &&
                        data &&
                        data.insert_linked_in_post_content_one
                      ) {
                        setSuggestedPlan([
                          ...suggestedPlan.slice(0, rowIndex),
                          ...suggestedPlan.slice(rowIndex || 0 + 1),
                        ]);
                        postsQuery.refetch();
                      }
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    appearance="primary"
                    color="orange"
                    onClick={(event) => {
                      event.preventDefault();
                      setSuggestedPlan([
                        ...suggestedPlan.slice(0, rowIndex),
                        ...suggestedPlan.slice(rowIndex || 0 + 1),
                      ]);
                    }}
                  >
                    Reject
                  </Button>
                </ButtonToolbar>
              )}
            </Table.Cell>
          </Table.Column>
        </Table>
      )}
    </>
  );
};

export default GenerateCampaignPlan;

import Icon from "@rsuite/icons/lib/Icon";
import React, { useContext, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { SelectTileContext } from "../SelectTileGroup";

const SelectTile: React.FC<{ value: string; children: React.ReactNode }> = ({
  value,
  children,
}) => {
  const { selectedValues, onSelectionChange } = useContext(SelectTileContext);
  const [isSelected, setIsSelected] = React.useState(
    selectedValues.includes(value)
  );

  useEffect(() => {
    setIsSelected(selectedValues.includes(value));
  }, [selectedValues, value]);

  return (
    <div
      className={`cursor-pointer p-1 overflow-hidden relative select-tile ${
        isSelected ? "border-green-600 border-4 border-solid" : ""
      }`}
      onClick={() => {
        setIsSelected(!isSelected);
        onSelectionChange(value);
      }}
    >
      {isSelected && (
        <>
          <Icon
            className="absolute top-0 left-0 text-lg z-10"
            as={FaCheckCircle}
            color="white"
          />
          <div
            className="absolute z-0 rounded-full bg-green-600"
            style={{
              width: "50px",
              height: "50px",
              top: "-25px",
              left: "-25px",
              overflow: "hidden",
            }}
          ></div>
        </>
      )}
      {children}
    </div>
  );
};

export default SelectTile;

// Integrations.tsx
import React from "react";
import LinkedInAuthButton from "./LinkedInAuthButton";
import LinkedInCommunityManagementAuthButton from "./LinkedInCommunityManagementAuthButton";
import { FlexboxGrid, Panel } from "rsuite";

const Integrations: React.FC = () => {
  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item colspan={12}>
        <Panel bordered>
          <h1 className="text-center">Integrations</h1>
          <LinkedInAuthButton
            onAuthSuccess={() => console.log("auth success")}
          />
          <LinkedInCommunityManagementAuthButton
            onAuthSuccess={() => console.log("auth success")}
          />
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Integrations;

import { ApolloQueryResult, useMutation, useQuery } from "@apollo/client";
import { User as Auth0User } from "@auth0/auth0-react";
import React, { createContext, useContext, useEffect } from "react";
import {
  CreateUserDocument,
  GetUserDocument,
  GetUserQuery,
} from "../graphql/__generated__/graphql";
import { Loading } from "./Loading";
import useAxios from "axios-hooks";
import { DisplayError } from "./DisplayError";

interface InitialiseUserProps {
  children: React.ReactNode; // Declare children here
  auth0User: Auth0User;
}

export type UserInReactContext = NonNullable<GetUserQuery["user_by_pk"]>;
type UserContextType = {
  user: UserInReactContext;
  refetchUser: (
    variables?:
      | Partial<{
          id: string;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<GetUserQuery>>;
};
const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

// basic react component
const InitializeApp: React.FC<InitialiseUserProps> = ({
  children,
  auth0User,
}) => {
  const [
    {
      loading: loadingInitialize,
      error: errorInitialize,
      data: dataInitialize,
    },
  ] = useAxios({
    method: "post",
    url: `${process.env.API_URL}/initialize-user`,
    data: {
      name: auth0User.name,
      picture: auth0User.picture,
    },
  });

  const {
    loading: loadingUser,
    error: errorUser,
    data: dataUser,
    refetch: refetchUser,
  } = useQuery(GetUserDocument, {
    skip: !dataInitialize,
    variables: { id: auth0User.sub as string },
  });

  if (loadingInitialize || loadingUser)
    return <Loading message="Loading user..." />;
  if (errorInitialize || errorUser) {
    console.error("Error loading user", errorInitialize || errorUser);
    return <DisplayError error={errorInitialize || errorUser} />;
  }

  if (dataInitialize && dataUser && dataUser.user_by_pk) {
    return (
      <UserContext.Provider value={{ user: dataUser.user_by_pk, refetchUser }}>
        {children}
      </UserContext.Provider>
    );
  } else {
    console.error("Error initialising user");
    return (
      <DisplayError error="Something went wrong... Was not able to load the user." />
    );
  }
};

export default InitializeApp;

import { useNavigate } from "react-router-dom";
import React from "react";
import { UserInReactContext } from "./InitialiseApp";
import { Sidenav, Sidebar as RSidebar, SelectPicker, Nav } from "rsuite";
import { useMarketingUnit } from "./MarketingUnitContext";
import {
  FaBroadcastTower,
  FaBullseye,
  FaFileAlt,
  FaList,
} from "react-icons/fa";
import Icon from "@rsuite/icons/lib/Icon";
import CustomSidenavLink from "./CustomSideNavLink";

// @ts-ignore


const Sidebar: React.FC<{
  user: UserInReactContext;
  children?: React.ReactNode;
}> = ({ user }) => {
  const navigate = useNavigate();

  const marketingUnitContext = useMarketingUnit();
  const menuItems = [
    {
      name: "Content",
      path: "content",
      icon: FaFileAlt,
    },
    {
      name: "Campaigns",
      path: "campaigns",
      icon: FaBroadcastTower,
    },
    {
      name: "Brand & Strategy",
      icon: FaBullseye,
      path: "brand-and-strategy",
    }
  ];

  return (
    <RSidebar>
      <Sidenav className="rounded-md">
        <Sidenav.Header>
          <SelectPicker
            cleanable={false}
            onSelect={(value) => {
              navigate(`/unit/${value}`);
            }}
            label="Marketing Unit"
            block
            value={marketingUnitContext.marketingUnit.id}
            data={user.marketing_units.map((unit) => ({
              label: `${unit.name} (${unit.type})`,
              value: unit.id,
            }))}
          />
        </Sidenav.Header>
        <Sidenav.Body>
          <Nav className="mb-1">
            {menuItems.map((item) => (
              <Nav.Item
                key={item.name}
                as={CustomSidenavLink}
                to={item.path}
                icon={<Icon as={item.icon} />}
              >
                {item.name}
              </Nav.Item>
            ))}
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </RSidebar>
  );
};

export default Sidebar;

import React from "react";

export const Loading: React.FC<{ message?: string }> = ({ message }) => {
  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className="p-2 rounded-lg">
        <p>{message || "Loading..."}</p>
      </div>
    </div>
  );
};

import React from "react";
import { Avatar, Container, Content, Header, Panel } from "rsuite";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "rsuite";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect();
  };

  return (
    <Container className="flex flex-1">
      <Header>
        <Avatar src="/public/logo.png" circle alt="lvr.ai logo" />
      </Header>
      <Content className="flex items-center justify-center">
        <Panel className="p-8" header={<h3>Login to LVR.AI</h3>} bordered>
          <Button appearance="primary" onClick={handleLogin}>
            Sign in
          </Button>
          <Button appearance="link" href="https://forms.gle/UiLus4GFSoNcNgZw6">
            Join the Waitlist
          </Button>
        </Panel>
      </Content>
    </Container>
  );
};

export default LoginPage;

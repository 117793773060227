import Icon from "@rsuite/icons/lib/Icon";
import { format } from "date-fns";
import { Emoji } from "emoji-picker-react";
import React from "react";
import { FaMagic, FaPlus } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import {
  Avatar,
  Button,
  ButtonToolbar,
  Drawer,
  Panel,
  Stack,
  Table,
} from "rsuite";
import { getAuthorPicture } from "../utils";
import { DisplayError } from "./DisplayError";
import GenerateCampaignPlan from "./GenerateCampaignPlan";
import { useMarketingUnit } from "./MarketingUnitContext";

const LinkedInPostList: React.FC = () => {
  const { postsQuery, marketingUnit } = useMarketingUnit();
  const [openDrawer, setOpenDrawer] = React.useState(false);

  if (postsQuery.error) return <DisplayError error={postsQuery.error} />;

  return (
    <Panel>
      <h1>Content</h1>
      <ButtonToolbar className="mt-1 mb-1">
        <Button
          as={NavLink}
          to="../content/editor"
          startIcon={<Icon as={FaPlus} />}
          appearance="primary"
        >
          Create Single Piece of Content
        </Button>
        <Button
          startIcon={<FaMagic />}
          appearance="link"
          onClick={() => setOpenDrawer(true)}
        >
          Create a Series
        </Button>
      </ButtonToolbar>

      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} size="lg">
        <Drawer.Body>
          <GenerateCampaignPlan />
        </Drawer.Body>
      </Drawer>
      <Table
        autoHeight
        loading={postsQuery.loading}
        data={postsQuery.data ? postsQuery.data.linked_in_post_content : []}
      >
        <Table.Column fullText flexGrow={1}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell dataKey="name"></Table.Cell>
        </Table.Column>
        <Table.Column fullText flexGrow={2}>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.Cell dataKey="description"></Table.Cell>
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Publish as</Table.HeaderCell>
          <Table.Cell style={{ padding: "6px" }}>
            {() => (
              <Stack spacing={5} alignItems="center">
                <Avatar
                  size="sm"
                  circle
                  src={
                    marketingUnit.linked_in_author
                      ? getAuthorPicture(marketingUnit.linked_in_author)
                      : ""
                  }
                >
                  {String(marketingUnit.name).charAt(0)}
                </Avatar>
              </Stack>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150}>
          <Table.HeaderCell>Publication status</Table.HeaderCell>
          <Table.Cell dataKey="publication.state"></Table.Cell>
        </Table.Column>
        <Table.Column width={200} fullText>
          <Table.HeaderCell>Publication date</Table.HeaderCell>
          <Table.Cell>
            {(rowData) =>
              format(
                new Date(rowData.publication.publicationDate),
                "MMMM d, yyyy h:mm aa"
              )
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Campaign</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              const { campaign } = rowData;
              if (!campaign) return "";
              const {color, emoji, name} = campaign
              return (
                <Stack spacing={3} alignItems="center">
                  <Avatar size="sm" style={{ background: color }}>
                    {emoji && <Emoji unified={emoji} size={18} />}
                  </Avatar>
                  <span>{name}</span>
                </Stack>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} fixed="right">
          <Table.HeaderCell>Operations</Table.HeaderCell>
          <Table.Cell style={{ padding: "6px" }}>
            {(rowData) => (
              <Button
                as={NavLink}
                appearance="primary"
                to={`editor/${rowData.id}`}
              >
                Edit
              </Button>
            )}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Panel>
  );
};

export default LinkedInPostList;

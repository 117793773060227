// LinkedInAuthButton.tsx

import { faCheckCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxios from "axios-hooks";
import React, { useEffect } from "react";
import { DisplayError } from "./DisplayError";
import Icon from "@rsuite/icons/lib/Icon";
import { FaLinkedin } from "react-icons/fa";
import { Button, Panel, Stack } from "rsuite";
import { useUser } from "./InitialiseApp";

interface LinkedInCommunityManagementAuthButtonProps {
  onAuthSuccess: () => void;
}

const LinkedInCommunityManagementAuthButton: React.FC<
  LinkedInCommunityManagementAuthButtonProps
> = ({ onAuthSuccess }) => {
  const { refetchUser } = useUser();
  const [
    {
      data: isLinkedInConnectedResponse,
      loading: loadingIsLinkedInConnected,
      error: errorIsLinkedInConnected,
    },
    refetchIsLinkedInConnected,
  ] = useAxios({
    url: `linkedin-communityManagement-connection-info`,
    baseURL: `${process.env.API_URL}/`,
    method: "post",
  });

  const [
    {
      data: storeLinkedInAccessTokenResponse,
      loading: loadingStoreLinkedInAccessToken,
      error: errorStoreLinkedInAccessToken,
    },
    executeStoreLinkedInAccessToken,
  ] = useAxios(
    {
      url: `store-linkedin-access-token`,
      baseURL: `${process.env.API_URL}/`,
      method: "post",
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (
      !loadingStoreLinkedInAccessToken &&
      !!storeLinkedInAccessTokenResponse
    ) {
      refetchIsLinkedInConnected();
    }
  }, [loadingStoreLinkedInAccessToken, storeLinkedInAccessTokenResponse]);

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      if (
        event.origin === `${process.env.API_URL}` &&
        event.data.type === "linkedin-auth-communityManagement"
      ) {
        const accessToken = event.data.accessToken;
        try {
          await executeStoreLinkedInAccessToken({
            data: {
              app: "communityManagement",
              token: accessToken,
            },
          });
          await refetchUser();
        } catch (error) {
          console.error("Error saving linkedin access token:", error);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [onAuthSuccess]);

  const handleClick = () => {
    if (!isLinkedInConnectedResponse.isConnected) {
      window.open(
        `${process.env.API_URL}/auth/linkedin-communityManagement`,
        "auth/linkedin-communityManagement",
        "width=600,height=800"
      );
    }
  };

  if (errorIsLinkedInConnected) {
    return <DisplayError error={errorIsLinkedInConnected} />;
  } else if (errorStoreLinkedInAccessToken) {
    return <DisplayError error={errorStoreLinkedInAccessToken} />;
  }

  return (
    <Panel
      bordered
      style={{
        display: "inline-block",
        textAlign: "center",
        width: "100%",
      }}
    >
      <Stack direction="column" spacing={8}>
        <Stack.Item>
          <Icon
            as={FaLinkedin}
            style={{ fontSize: "5em", color: "blue" }}
          ></Icon>
          <div>
            <h5>LinkedIn Community</h5>
            <p>
              <em className="text-sm">
                This connection will allow you to manage companies on LinkedIn.
                Necessary if you want to publish content as a company.
              </em>
            </p>
          </div>
        </Stack.Item>
        <Stack.Item>
          <Button
            appearance="primary"
            color="green"
            onClick={handleClick}
            disabled={
              loadingStoreLinkedInAccessToken ||
              loadingIsLinkedInConnected ||
              !isLinkedInConnectedResponse ||
              isLinkedInConnectedResponse.isConnected
            }
          >
            {loadingIsLinkedInConnected ? (
              <>
                <p>Checking...</p>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="animate-spin w-4"
                />
              </>
            ) : loadingStoreLinkedInAccessToken ? (
              <>
                <p>Connecting...</p>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="animate-spin w-4"
                />
              </>
            ) : (
              <>
                <p>
                  {isLinkedInConnectedResponse.isConnected ? (
                    <span>{`Your are connected`}</span>
                  ) : (
                    "Connect"
                  )}
                </p>
                {isLinkedInConnectedResponse.isConnected && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-xl w-4"
                  />
                )}
              </>
            )}
          </Button>
        </Stack.Item>
      </Stack>
    </Panel>
  );
};

export default LinkedInCommunityManagementAuthButton;

import React from "react";
import { Input } from "rsuite";

const Textarea = React.forwardRef<HTMLTextAreaElement>((props, ref) => (
  <Input
    {...props}
    // @ts-ignore
    value={props.value === null ? "" : props.value}
    as="textarea"
    rows={3}
    ref={ref}
  />
));

export default Textarea;

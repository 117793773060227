// ApolloClient.ts
import { ApolloClient, InMemoryCache } from '@apollo/client';

const createApolloClient = (token: string) => {
    const config = {
        uri: process.env.GRAPHQL_URL,
        cache: new InMemoryCache(),
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    console.log('Apollo Config', config);
    return new ApolloClient(config);
}

export default createApolloClient;